import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Text from "../common/Text";
import { useGetRecipesCategory } from "../../utils/API";
import NoContent from "../common/NoContent";
import Container from "../common/Container";
import { getQueryParams } from "../../utils/helpers";

function Recipes({ products = [] }) {
    const navigate = useNavigate();
    const { tenant, branch, table } = useParams();
    const { isLoading, data } = useGetRecipesCategory();
    const [SearchQuery, setSearchQuery] = useState("");

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        if (SearchQuery) {
            let SearchProducts = [];
            data?.results.forEach((item) => {
                if (item?.title_en.toLowerCase().includes(SearchQuery.toLowerCase()) || item?.title_ar?.includes(SearchQuery)) SearchProducts.push(item);
            });
            setCategories(SearchProducts);
        } else {
            setCategories(data?.results);
        }
    }, [SearchQuery, data?.results]);

    const toCategories = async (id) => {
        const queryString = await getQueryParams();
        const basePath = table ? `/${tenant}/${branch}/${table}/recipes/${id}/` : `/${tenant}/${branch}/recipes/${id}/`;
        navigate(basePath + (queryString ? `?${queryString}` : ""));
    };
    useEffect(() => {
        setCategories(data?.results);
    }, [data]);

    if (data?.results === null) return <NoContent />;
    return (
        <>
            <Container viewSearch={true} SearchQuery={SearchQuery} setSearchQuery={setSearchQuery} title="Recipes Categories " viewBottomTab={true} isLoading={isLoading}>
                <div className="row mb-3">
                    <div className="col align-self-center">
                        <h6 className="title">
                            <Text title={"Recipes Categories"} />
                        </h6>
                        <p className="text-secondary small">{products.length}</p>
                    </div>
                </div>
                <div className="row mb-2">
                    {categories?.map((item, index) => (
                        <div className="row mb-4" onClick={() => toCategories(item?.id)}>
                            <div className="col-12">
                                <div className="card theme-bg overflow-hidden">
                                    <div className="position-absolute top-0 end-0 h-100" style={{ height: "100%" }}>
                                        <img
                                            style={{
                                                width: 200,
                                                height: "100%",
                                                borderRadius: " 0px 0 px 40px 50px",
                                                borderTopLeftRadius: 9,
                                                borderBottomLeftRadius: "121px",
                                            }}
                                            src={item?.image}
                                            alt=""
                                        />
                                    </div>
                                    <div className="card-body z-index-1">
                                        <div className="row my-3">
                                            <div className="col-10 align-self-center">
                                                <h1 className="mb-3">
                                                    <span className="fw-light">{item?.title} &</span>
                                                    <br />
                                                    {item?.subtitle}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </>
    );
}

export default Recipes;
