import React, { useEffect } from "react";
import BottomTab from "../../components/common/BottomTab";
import "./Coloren.css";
import { useNavigate } from "react-router-dom";

function Games() {
    const Navigate = useNavigate();
    useEffect(() => {
        const scriptj = document.createElement("script");
        // scriptj.src = "";
        scriptj.async = true;
        document.body.appendChild(scriptj);

        const script = document.createElement("script");
        script.src = "coloren.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Cleanup
            document.body.removeChild(script);
            document.body.removeChild(scriptj);
        };
    }, []);
    return (
        <div className="coloren">
            <div
                style={{
                    fontSize: "50px",
                    margin: "0px",
                    position: "fixed",
                    zIndex: 555555,
                }}
                onClick={() => Navigate(-1)}
            >
                <i style={{ color: "grey" }} className="bi bi-arrow-left-short"></i>
            </div>

            <div className="splash"></div>
            <div className="container">
                <div className="start-game game-full-flex" id="start-game">
                    <div className="start-game-top">
                        <a className="play-full-page" href="https://codepen.io/gregh/full/yVLOyO/" target="_blank">
                            Full Page Mode
                        </a>
                    </div>

                    <div className="logo-holder">
                        <p className="logo">
                            <span>C</span>
                            <span>o</span>
                            <span>l</span>
                            <span>o</span>
                            <span>r</span>
                            <span>o</span>
                            <span>n</span>
                        </p>
                        <a className="play-button" href="#" id="start" onclick="game.start()">
                            Play
                        </a>
                        <h4 className="hint">
                            hint: <span>red</span> color always comes first
                        </h4>
                    </div>

                    <div className="how-to-play">
                        <div className="section section-1">
                            <h4>
                                Bouncing ball
                                <br />
                                changes color
                            </h4>
                            <div className="content">
                                <div className="ball-demo" id="ball-demo"></div>
                            </div>
                        </div>
                        <div className="section section-2">
                            <h4>
                                Tap on the bar to switch the colors
                                <br />
                                (Red, Yellow, Purple)
                            </h4>
                            <div className="content">
                                <div className="bar bar-1" data-index="0"></div>
                                <div className="bar bar-2"></div>
                                <div className="bar bar-3"></div>
                            </div>
                        </div>
                        <div className="section section-3">
                            <h4>
                                Always match the
                                <br />
                                ball and bar colors
                            </h4>
                            <div className="content">
                                <div className="ball-demo" id="ball-demo"></div>
                                <div className="bar bar-1"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="stop-game game-full-flex">
                    <div className="score-container">
                        <h1>Coloron</h1>

                        <div className="final-score"></div>
                        <div className="result"></div>

                        <h4>Tweet to challenge friends</h4>

                        <p>
                            <a className="tweet" id="tweet" href="#" onclick="game.generateTweet()">
                                <i className="fa fa-twitter" aria-hidden="true"></i> Tweet
                            </a>
                        </p>

                        <div>
                            <a className="play-again" id="play-again" href="#">
                                Play Again
                            </a>
                            <a className="main-menu" href="#" id="menu-main">
                                Menu
                            </a>
                        </div>
                    </div>
                </div>

                <div className="small-glows"></div>

                <div className="glow">
                    <div className="sun"></div>
                </div>

                <div className="waves">
                    <div className="top_wave"></div>
                    <div className="wave1"></div>
                    <div className="wave2"></div>
                    <div className="wave3"></div>
                    <div className="wave4"></div>
                </div>

                <div className="mounts">
                    <div className="mount1"></div>
                    <div className="mount2"></div>
                </div>

                <div className="clouds"></div>

                <div className="scene">
                    <div className="learn-to-play">Click on the bars to change the color!</div>
                    <div className="score" id="score"></div>
                    <div className="ball-holder"></div>
                    <div className="sticks" id="sticks"></div>
                </div>

                <div className="noise"></div>
            </div>
        </div>
    );
}

export default Games;
