import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Text from "./Text";
import { useCart, useLanguage } from "../../utils/Hooks";

function CheckoutModal({ openModal, setOpenModal }) {
    const { Socket } = useCart();
    const { userLanguage } = useLanguage();

    const [socket, setSocket] = useState(null);
    useEffect(() => {
        setSocket(Socket);
    }, [Socket]);

    const handleCheckout = () => {
        if (socket) {
            socket.send(
                JSON.stringify({
                    action: "checkout",
                    language: userLanguage,
                    payload: {
                        method: "cash",
                    },
                })
            );
        }
        setOpenModal(false);
    };

    return (
        <Modal show={openModal} centered onHide={() => setOpenModal(false)} animation={false}>
            <Modal.Header className="bg-light" closeButton>
                <Modal.Title>
                    <Text title="CheckOut" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-light">
                <Form.Label htmlFor="options">
                    <Text title="Options" />
                </Form.Label>
                <Form.Select id="options" className="mb-2">
                    <option value="1" selected>
                        <Text title="Cash" />
                    </option>
                </Form.Select>
            </Modal.Body>
            <Modal.Footer className="bg-light">
                <Button variant="primary" onClick={() => handleCheckout(false)}>
                    <Text title="Confirm" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CheckoutModal;
