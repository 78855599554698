import React from "react";
import { Button } from "react-bootstrap";
import { useTheme } from "../../utils/Hooks";
import { useParams } from "react-router-dom";

function SocialLinks({ online }) {
    const { SocialLinks } = useTheme();
    const { table } = useParams();

    return (
        SocialLinks && (
            <div className={online ? "social-media" : "social-media-x"}>
                {SocialLinks?.phone && (
                    <Button variant="light" className="p-2 radius-circle mx-1" onClick={() => window.open(`tel:${SocialLinks.phone}`, "_blank")}>
                        <i className="bi bi-telephone-fill" />
                    </Button>
                )}
                {SocialLinks?.facebook && (
                    <Button variant="light" className="p-2 radius-circle mx-1" onClick={() => window.open(SocialLinks?.facebook, "_blank")}>
                        <i className="bi bi-facebook" />
                    </Button>
                )}
                {SocialLinks?.whatsapp_active && SocialLinks?.whatsapp_number && (
                    <Button variant="light" className="p-2 radius-circle mx-1" onClick={() => window.open(`https://wa.me/${SocialLinks?.whatsapp_number}?text=${SocialLinks?.whatsapp_msg}`, "_blank")}>
                        <i className="bi bi-whatsapp" />
                    </Button>
                )}
                {SocialLinks?.instagram && (
                    <Button variant="light" className="p-2 radius-circle mx-1" onClick={() => window.open(SocialLinks?.instagram, "_blank")}>
                        <i className="bi bi-instagram" />
                    </Button>
                )}
                {SocialLinks?.twitter && (
                    <Button variant="light" className="p-2 radius-circle mx-1" onClick={() => window.open(SocialLinks?.twitter, "_blank")}>
                        <i className="bi bi-twitter" />
                    </Button>
                )}
                {SocialLinks?.youtube && (
                    <Button variant="light" className="p-2 radius-circle mx-1" onClick={() => window.open(SocialLinks?.youtube, "_blank")}>
                        <i className="bi bi-youtube" />
                    </Button>
                )}
                {SocialLinks?.linkedin && (
                    <Button variant="light" className="p-2 radius-circle mx-1" onClick={() => window.open(SocialLinks?.linkedin, "_blank")}>
                        <i className="bi bi-linkedin" />
                    </Button>
                )}

                {!table && (
                    <Button
                        variant="light"
                        className="p-2 radius-circle mx-1"
                        onClick={() => {
                            const shareData = {
                                title: `QR Menu`,
                                text: `QR-Menu`,
                                url: document.URL,
                            };
                            navigator.share(shareData);
                        }}
                    >
                        <i className="bi bi-share-fill" />
                    </Button>
                )}
            </div>
        )
    );
}

export default SocialLinks;
