import React, { useEffect, useLayoutEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Badge, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";

import Container from "../components/common/Container";

import { useGetProductHook, useGetRecipesById } from "../utils/API";
import Text, { Price, Title } from "../components/common/Text";
import { useCart, useTheme } from "../utils/Hooks";

function RecipeDetails() {
    const { id } = useParams();
    const [OneRecipe, setOneRecipes] = useState(null);
    const { data: product, isLoading } = useGetProductHook(id);
    const { data } = useGetRecipesById(id);
    useEffect(() => {
        setOneRecipes(data);
    }, [data]);
    const [show, setShow] = useState(false);

    const { style } = useTheme();
    const [Direction, setDirection] = useState(style["direction-mode"]);
    const [swiper1, setSwiper1] = useState(null);
    const [swiper2, setSwiper2] = useState(null);

    useLayoutEffect(() => {
        if (style["direction-mode"] !== Direction && swiper1 && swiper2) {
            swiper1.changeLanguageDirection(style["direction-mode"]);
            swiper2.changeLanguageDirection(style["direction-mode"]);
            setDirection(style["direction-mode"]);
        }
    }, [style, Direction, swiper1, swiper2]);

    return (
        <Container viewBottomTab={true} isLoading={isLoading} title={OneRecipe?.title}>
            <div className="row">
                <div className="col-12 px-3 mt-3 d-flex justify-content-center position-relative">
                    <div className="card shadow-sm mb-3">
                        <div className="card-body p-0" role={"button"} onClick={() => setShow(true)}>
                            <img alt="" src={OneRecipe?.image ? OneRecipe?.image : "https://img-global.cpcdn.com/recipes/8de0e49de9905f32/160x120cq70/card_recipe_image.jpg"} className="avatar avatar-300 rounded-18 coverimg text-center" />
                        </div>
                        <Modal className={"imgModal"} centered show={show} onHide={() => setShow(false)}>
                            <img src={OneRecipe?.image ? OneRecipe?.image : "https://img-global.cpcdn.com/recipes/8de0e49de9905f32/160x120cq70/card_recipe_image.jpg"} alt={OneRecipe?.id} style={{ minHeight: "320px" }} />
                        </Modal>
                    </div>
                </div>
            </div>
            <h4 className="mb-2">
                <div dangerouslySetInnerHTML={{ __html: OneRecipe?.title }} />
            </h4>
            <div className="row mb-3">
                {/* <div className="col align-self-center">
          <h5>
            <Price price={product?.price} />
          </h5>
        </div> */}
                {/* <div className="col-auto">
          <div className="counter-number">
            <button
              className="btn btn-sm avatar avatar-30 p-0 rounded-circle"
              onClick={() => setCounter(Counter > 1 ? Counter - 1 : Counter)}
            >
              <i className="bi bi-dash size-22"></i>
            </button>
            <span>{Counter}</span>
            <button
              className="btn btn-sm avatar avatar-30 p-0 rounded-circle"
              onClick={() => setCounter(Counter + 1)}
            >
              <i className="bi bi-plus size-22"></i>
            </button>
          </div>
        </div> */}
            </div>

            {/* <div className="row mb-4">
        <div className="col">
          <button
            onClick={() => AddCart(product)}
            className="btn btn-default btn-lg shadow-sm w-100 btn-rounded"
          >
            <Text title="AddToCart" />
          </button>
        </div>
      </div> */}

            <>
                <div className="fs-5 m-0">
                    <>
                        <div>
                            <h5 className="mb-3">
                                <Text title={"Ingredients"} />
                            </h5>
                            <div className="fs-5 m-2 mb-3">
                                <div className="card-body p-1 d-flex align-items-center text-wrap justify-content-between">
                                    <p className="text-center size-13 px-2 m-0">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: OneRecipe?.ingredients,
                                            }}
                                        />
                                    </p>
                                    {/* <p className="text-center size-13 px-2 m-0">
                              <small>
                                <Price price={modifier?.price} />
                              </small>
                            </p> */}
                                </div>
                            </div>
                        </div>
                        <div>
                            <h5 className="mb-3">
                                <Text title={"Steps"} />
                            </h5>
                            <div className="fs-5 m-2 mb-3">
                                <div className="card-body p-1 d-flex align-items-center text-wrap justify-content-between">
                                    <p className="text-center size-13 px-2 m-0">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: OneRecipe?.steps,
                                            }}
                                        />
                                    </p>
                                    {/* <p className="text-center size-13 px-2 m-0">
                           <small>
                             <Price price={modifier?.price} />
                           </small>
                         </p> */}
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </>

            {product?.nutrients?.length > 0 && (
                <>
                    <h5 className="my-3">
                        <Text title={"Nutrition's"} />
                    </h5>
                    <Swiper onSwiper={(swiper) => setSwiper2(swiper)} spaceBetween={2} slidesPerView={"auto"}>
                        {product?.nutrients?.map((nutrient, index) => (
                            <SwiperSlide key={index}>
                                <div className="card-body p-1 d-flex flex-column align-items-center text-wrap">
                                    <div
                                        className={"card text-center m-1 text-muted"}
                                        bg={nutrient?.type?.color}
                                        style={{
                                            borderRadius: "50px",
                                            background: nutrient?.type?.color,
                                            width: "55px",
                                            height: "55px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontSize: "14px",
                                        }}
                                    >
                                        <p className="text-center text-black size-11 px-2 m-0 w-100">
                                            {nutrient.value} <Title en={nutrient?.unit?.name_en} ar={nutrient?.unit?.name_ar} />
                                        </p>
                                    </div>
                                    <p
                                        className="text-center size-13 px-auto m-0"
                                        style={{
                                            width: "55px",
                                        }}
                                    >
                                        <small>
                                            <Title en={nutrient?.type?.name_en} ar={nutrient?.type?.name_ar} />
                                        </small>
                                    </p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </>
            )}

            {/* <div className="row mb-3">
        <div className="col-12 text-center my-3">
          <button
            className="btn btn-sm btn-outline-info mx-1 btn-rounded"
            onClick={handleShare}
          >
            <i className="bi bi-share"></i> <Text title="Share" />
          </button>
        </div>
      </div> */}
            <div className="clearfix"></div>
        </Container>
    );
}

export default RecipeDetails;
