import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-toastify/dist/ReactToastify.css";
import "leaflet/dist/leaflet.css";
import "./assets/scss/style.css";
import "swiper/css";
import "moment/locale/ar";
import { ThemeProvider } from "./utils/ThemeHook";
import { CartProvider } from "./utils/CartHook";
import { GuestProvider } from "./utils/GuestHook";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ThemeProvider>
                <CartProvider>
                    <GuestProvider>
                        <App />
                    </GuestProvider>
                </CartProvider>
            </ThemeProvider>
        </QueryClientProvider>
    </React.StrictMode>
);
reportWebVitals();
