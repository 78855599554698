import React from "react";
import Container from "../components/common/Container";
import NoContent from "../components/common/NoContent";
import Text, { Title } from "../components/common/Text";
import { useGetContactPage } from "../utils/API";

function ContactUs() {
    const { isLoading, data } = useGetContactPage();

    const handleMail = () => {
        window.location.href = `mailto:${data?.results?.email}?subject=${"get started"}`;
    };
    const handleWhats = () => {
        window.location.href = `//api.whatsapp.com/send?phone=${data?.results?.mobile}&text=${"get started"}`;
    };

    return (
        <Container isLoading={isLoading} title="Contact Us">
            {data?.results === null ? (
                <NoContent />
            ) : (
                <>
                    <div className="row justify-content-center mt-3">
                        <div className="col-6">
                            <div className="card shadow-sm mb-4">
                                <div className="card-body text-center">
                                    <div className="avatar avatar-60 shadow-sm shadow-primary rounded-15 mb-3 bg-green text-white">
                                        <div className="icons">
                                            <i className="bi bi-whatsapp size-22"></i>
                                        </div>
                                    </div>
                                    <h6 className="mb-2">
                                        <Text title={"WhatsApp"} />
                                    </h6>
                                    <p className="text-secondary small mb-3">
                                        <Text title={"likeToHear"} />
                                    </p>
                                    <button className="btn btn-sm text-color-theme" onClick={handleWhats}>
                                        <i className="bi bi-whatsapp mx-1"></i>
                                        <Text title={"callUs"} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card shadow-sm mb-4">
                                <div className="card-body text-center">
                                    <div className="avatar avatar-60 shadow-sm shadow-primary rounded-15 mb-3 bg-red text-white">
                                        <div className="icons">
                                            <i className="bi bi-envelope size-22"></i>
                                        </div>
                                    </div>
                                    <h6 className="mb-2">
                                        <Text title={"Email"} />
                                    </h6>
                                    <p className="text-secondary small mb-3">
                                        <Text title={"likeToHear"} />
                                    </p>
                                    <button className="btn btn-sm text-color-theme" onClick={handleMail}>
                                        <i className="bi bi-envelope mx-1"></i>
                                        <Text title={"MailUs"} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-4">
                            <h6 className="mb-2">
                                <Text title={"TechnicalProblem"} />
                            </h6>
                            <p className="text-secondary">
                                <Text title={"FacingGeneral"} />
                                <span className="text-primary" role={"button"} onClick={handleMail}>
                                    {" "}
                                    {data?.results?.email}
                                </span>
                                .
                            </p>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col">
                            <h6 className="my-1">
                                <Text title={"OurLocation"} />
                            </h6>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="card shadow-sm">
                                <div className="card-body">
                                    <iframe title={"location"} src={data?.results?.map_url} className="h-190 w-100 rounded mb-3" allowFullScreen loading="lazy"></iframe>

                                    <h6 className="text-primary mb-2">
                                        <Text title={"address"} />
                                    </h6>
                                    <p className="text-secondary">
                                        <Title en={data?.results?.address_en} ar={data?.results?.address_ar} title={data?.results?.address} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Container>
    );
}

export default ContactUs;
