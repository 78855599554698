import React, { Fragment, useLayoutEffect, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Badge, Button, Form, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Container from "../components/common/Container";
import { useGetProductHook, usePostReview } from "../utils/API";
import Text, { Price, Title } from "../components/common/Text";
import { useCart, useGuest, useLanguage, useTheme } from "../utils/Hooks";
import { toast } from "react-toastify";

function Product() {
    const [openGuestModal, setOpenGuestModal] = useState(false);

    const { guest } = useGuest();

    const { id, branch, table } = useParams();
    const { userLanguage } = useLanguage();
    const { data: product, isLoading } = useGetProductHook(id);

    const theme = localStorage.getItem("theme-mode");
    const [Counter, setCounter] = useState(1);
    const [Modifiers, setModifiers] = useState([]);
    const [Variants, setVariants] = useState([]);

    const [show, setShow] = useState(false);

    const handleModifiers = (modifier, modifierClasses) => {
        const sameClass = Modifiers.filter((mod) => mod.classId === modifierClasses?.id);
        const index = Modifiers.findIndex((mod) => mod.id === modifier.id);
        const modifiers = [...Modifiers];
        if (index < 0) {
            if (sameClass?.length < modifierClasses?.maximum_amount || modifierClasses?.maximum_amount === 0) {
                modifiers.push({ ...modifier, classId: modifierClasses?.id });
                setModifiers(modifiers);
            }
        } else {
            modifiers.splice(index, 1);
            setModifiers(modifiers);
        }
    };

    const handleVariants = (variant) => {
        const variants = [...Variants];
        variants.pop();
        variants.push(variant);
        setVariants(variants);
    };

    const handleShare = () => {
        const shareData = {
            title: `QR Menu (${product?.title})`,
            text: `QR-Menu-${product?.title}`,
            url: document.URL,
        };
        navigator.share(shareData);
    };

    const { useAddProduct, Socket } = useCart();

    const [socket, setSocket] = useState(null);
    useEffect(() => {
        setSocket(Socket);
    }, [Socket]);

    const AddCart = (product) => {
        if (style?.is_branch_closed) {
            toast.warn(<Text title={"BranchClosed"} />);
        } else {
            useAddProduct({ ...product, Counter, Modifiers, Variants });
        }
    };

    const AddCartSocket = (product) => {
        if (style?.is_branch_closed) {
            toast.warn(<Text title={"BranchClosed"} />);
        } else {
            if (guest) {
                if (socket && product) {
                    socket.send(
                        JSON.stringify({
                            action: "add_or_update_item",
                            language: userLanguage,
                            payload: {
                                product_id: product?.id,
                                qty: Counter,
                                modifiers: Modifiers.map((mod) => ({
                                    id: mod?.id,
                                    qty: Counter,
                                })),
                            },
                        })
                    );
                    toast(<Text title={"productAdded"} />, {
                        theme: theme === "dark-mode" ? "dark" : "light",
                    });
                }
            } else setOpenGuestModal(true);
        }
    };

    const { style } = useTheme();
    const [Direction, setDirection] = useState(style["direction-mode"]);
    const [swiper1, setSwiper1] = useState(null);
    const [swiper2, setSwiper2] = useState(null);

    useLayoutEffect(() => {
        if (style["direction-mode"] !== Direction && swiper1 && swiper2) {
            swiper1.changeLanguageDirection(style["direction-mode"]);
            swiper2.changeLanguageDirection(style["direction-mode"]);
            setDirection(style["direction-mode"]);
        }
    }, [style, Direction, swiper1, swiper2]);

    const [ReviewModal, setReviewModal] = useState(false);
    const [ReviewForm, setReviewForm] = useState({
        name: "",
        comment: "",
        rate: 1,
    });

    const { mutate } = usePostReview();

    const handleReview = () => {
        const item = {
            product_id: parseInt(id),
            branch_slug: branch,
            ...ReviewForm,
        };
        mutate(item);
        setReviewModal(false);
        setReviewForm({
            name: "",
            comment: "",
            rate: 1,
        });
    };

    const stars = [5, 4, 3, 2, 1];

    return (
        <>
            <Container viewBottomTab={true} isLoading={isLoading} title={product?.title} openGuestModal={openGuestModal}>
                <div className="row mt-3">
                    <div className="col-12 px-3 mt-3 d-flex justify-content-center position-relative">
                        <div className="card shadow-sm mb-3">
                            <div className="card-body p-0" role={"button"} onClick={() => setShow(true)}>
                                <img className="avatar avatar-300 rounded-18 coverimg text-center" src={product?.is_image_url ? product?.image_url : product?.image} />
                            </div>
                            <Modal className={"imgModal"} centered show={show} onHide={() => setShow(false)}>
                                <img src={product?.is_image_url ? product?.image_url : product?.image} alt={product?.id} style={{ minHeight: "320px" }} />
                            </Modal>
                        </div>
                    </div>
                </div>
                <h4 className="mb-2">
                    <Title title={product?.title} en={product?.title_en} ar={product?.title_ar} />
                </h4>
                <div className="row mb-3">
                    <div className="col align-self-center">
                        <h5>
                            <span className={`${product?.price_after_discount ? "text-decoration-line-through" : ""}`}>
                                <Price price={product?.formated_price} />
                            </span>
                            {product?.price_after_discount && (
                                <span className={`mx-2`}>
                                    <Price price={product?.price_after_discount} />
                                </span>
                            )}
                            {product?.is_outofstock && (
                                <Badge className="m-2" bg={"theme"}>
                                    <Text title={"out_of_stock"} />
                                </Badge>
                            )}
                            {/* <s className="small text-secondary fw-light">$ 650.00</s> */}
                        </h5>
                    </div>
                    <div className="col-auto">
                        {!product?.is_outofstock && (
                            <div className="counter-number">
                                <button className="btn btn-sm avatar avatar-30 p-0 rounded-circle" onClick={() => setCounter(Counter > 1 ? Counter - 1 : Counter)}>
                                    <i className="bi bi-dash size-22"></i>
                                </button>
                                <span>{Counter}</span>
                                <button className="btn btn-sm avatar avatar-30 p-0 rounded-circle" onClick={() => setCounter(Counter + 1)}>
                                    <i className="bi bi-plus size-22"></i>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                {product?.desc && (
                    <div>
                        <h5 className="my-1">
                            <Text title={"description"} />
                        </h5>
                        <div className=" size-11 mb-2" dangerouslySetInnerHTML={{ __html: product?.desc }}></div>
                    </div>
                )}
                {product?.modifier_classes?.length > 0 && (
                    <>
                        <div className="fs-5 m-2 mb-3">
                            {product?.modifier_classes?.map(
                                (modifierClasses, i) =>
                                    modifierClasses?.modifiers?.length > 0 && (
                                        <div key={i}>
                                            <h5 className="mb-3 d-flex">
                                                <Title title={modifierClasses?.title} en={modifierClasses?.title_en} ar={modifierClasses?.title_ar} />
                                                {!modifierClasses?.minimum_amount && (
                                                    <p className="mx-2 size-14 align-self-center text-secondary">
                                                        <Text title={"(Optional)"} />
                                                    </p>
                                                )}
                                                {!!modifierClasses?.minimum_amount && (
                                                    <div className="mx-2 size-14 align-self-center text-warning">
                                                        <p className="text-muted">
                                                            *<Text title={"SelectMin"} /> {modifierClasses?.minimum_amount}, <Text title={"maximum"} /> {modifierClasses?.maximum_amount} <Text title={"options"} />
                                                        </p>
                                                    </div>
                                                )}
                                            </h5>
                                            <div className="fs-5 m-2 mb-3">
                                                {modifierClasses?.modifiers?.map((modifier, index) => (
                                                    <Badge
                                                        key={index}
                                                        role={"button"}
                                                        className={"card text-center m-1 w-100 " + (Modifiers.some((mod) => mod.id === modifier?.id) ? "text-white" : "text-muted")}
                                                        bg={Modifiers.some((mod) => mod.id === modifier?.id) ? "theme" : "light"}
                                                        onClick={() => handleModifiers(modifier, modifierClasses)}
                                                    >
                                                        <div className="card-body p-1 d-flex align-items-center text-wrap justify-content-between">
                                                            <p className="text-center size-13 px-2 m-0">
                                                                <Title en={modifier?.title_en} ar={modifier?.title_ar} />
                                                            </p>
                                                            <p className="text-center size-13 px-2 m-0">
                                                                <small>
                                                                    <Price price={modifier?.formated_price} />
                                                                </small>
                                                            </p>
                                                        </div>
                                                    </Badge>
                                                ))}
                                            </div>
                                        </div>
                                    )
                            )}
                        </div>
                    </>
                )}
                {product?.varients?.length > 0 && (
                    <>
                        <h5 className="mb-3">
                            <Text title={"Variants"} />
                        </h5>
                        <div className="fs-5 m-2 mb-3">
                            {product?.varients?.map((variant, index) => (
                                <Badge key={index} role={"button"} className={"card text-center m-1 w-100 " + (Variants.includes(variant) ? "text-white" : "text-muted")} bg={Variants.includes(variant) ? "theme" : "light"} onClick={() => handleVariants(variant)}>
                                    <div className="card-body p-1 d-flex align-items-center text-wrap justify-content-between">
                                        <p className="text-center size-13 px-2 m-0">
                                            <Title en={variant?.title_en} ar={variant?.title_ar} />
                                        </p>
                                        <p className="text-center size-13 px-2 m-0">
                                            <small>
                                                <Price price={variant?.formated_price} />
                                            </small>
                                        </p>
                                    </div>
                                </Badge>
                            ))}
                        </div>
                    </>
                )}

                <div className="row mb-4">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        {!product?.is_outofstock && (
                            <button
                                onClick={() => (table ? AddCartSocket(product) : AddCart(product))}
                                disabled={
                                    !product?.modifier_classes?.every((item) => {
                                        const sameClass = Modifiers.filter((mod) => mod.classId === item?.id);
                                        return item?.minimum_amount && item?.maximum_amount
                                            ? sameClass?.length >= item?.minimum_amount && sameClass?.length <= item?.maximum_amount
                                            : item?.maximum_amount
                                            ? sameClass?.length <= item?.maximum_amount
                                            : item?.minimum_amount
                                            ? sameClass?.length >= item?.minimum_amount
                                            : true;
                                    })
                                }
                                className="btn btn-default btn-lg shadow-sm w-100 btn-rounded"
                            >
                                <Text title="AddToCart" />
                            </button>
                        )}

                        <div className="d-flex justify-content-center w-100 my-4">
                            {!table && (
                                <Button className="mx-2" variant="info" onClick={handleShare}>
                                    <i className="bi bi-share"></i> <Text title="Share" />
                                </Button>
                            )}

                            <Button className="mx-2" variant="warning" onClick={() => setReviewModal(true)}>
                                <i className="bi bi-star"></i> <Text title="Review" />
                            </Button>
                        </div>
                    </div>
                </div>

                {product?.ingredients?.length > 0 && (
                    <>
                        <h5 className="my-3">
                            <Text title={"Ingredients"} />
                        </h5>

                        <Swiper onSwiper={(swiper) => setSwiper1(swiper)} spaceBetween={2} slidesPerView={"auto"} style={{ height: "fit-content" }}>
                            {product?.ingredients?.map((ingredient, index) => (
                                <SwiperSlide key={index}>
                                    <div className="card-body p-1 d-flex flex-column align-items-center text-wrap">
                                        <img className="avatar avatar-40 rounded-20 coverimg rounded-sub-img" src={ingredient?.image} />
                                        <Badge
                                            className={"card text-center m-1 text-muted"}
                                            bg={ingredient?.color}
                                            style={{
                                                borderRadius: "50px",
                                                background: ingredient?.color,
                                            }}
                                        >
                                            <p className="text-center text-black size-13 px-1">
                                                <small>
                                                    <Title en={ingredient?.name_en} ar={ingredient?.name_ar} />
                                                </small>
                                            </p>
                                        </Badge>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </>
                )}
                {product?.nutrients?.length > 0 && (
                    <>
                        <h5 className="my-3">
                            <Text title={"Nutrition's"} />
                        </h5>
                        <Swiper onSwiper={(swiper) => setSwiper2(swiper)} spaceBetween={2} slidesPerView={"auto"} style={{ height: "fit-content" }}>
                            {product?.nutrients?.map((nutrient, index) => (
                                <SwiperSlide key={index}>
                                    <div className="card-body p-1 d-flex flex-column align-items-center text-wrap">
                                        <div
                                            className={"card text-center m-1 text-muted"}
                                            bg={nutrient?.type?.color}
                                            style={{
                                                borderRadius: "50px",
                                                background: nutrient?.type?.color,
                                                width: "55px",
                                                height: "55px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                fontSize: "14px",
                                            }}
                                        >
                                            <p className="text-center text-black size-11 px-2 m-0 w-100">
                                                {nutrient.value} <Title en={nutrient?.unit?.name_en} ar={nutrient?.unit?.name_ar} />
                                            </p>
                                        </div>
                                        <p
                                            className="text-center size-13 px-auto m-0"
                                            style={{
                                                width: "55px",
                                            }}
                                        >
                                            <small>
                                                <Title en={nutrient?.type?.name_en} ar={nutrient?.type?.name_ar} />
                                            </small>
                                        </p>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </>
                )}
                <div className="mt-5 mb-5" />
            </Container>
            <Modal show={ReviewModal} centered onHide={() => setReviewModal(false)} animation={false}>
                <Modal.Header className="bg-light" closeButton>
                    <Modal.Title>
                        <Text title="ProductReview" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-light">
                    <Form.Label htmlFor="name">
                        <Text title="Name" />
                    </Form.Label>
                    <Form.Control id="name" type="text" className="mb-2" value={ReviewForm.name} onChange={(e) => setReviewForm((item) => ({ ...item, name: e.target.value }))} />

                    <Form.Label htmlFor="comment">
                        <Text title="Comment" />
                    </Form.Label>
                    <Form.Control id="comment" type="text" as="textarea" rows={3} className="mb-2" value={ReviewForm.comment} onChange={(e) => setReviewForm((item) => ({ ...item, comment: e.target.value }))} />

                    <Form.Label htmlFor="rate" className="m-0">
                        <Text title="Rate" />
                    </Form.Label>
                    <div className="d-flex justify-content-center">
                        <div className="rate">
                            {stars?.map((star, index) => (
                                <Fragment key={index}>
                                    <input
                                        type="radio"
                                        id={"star" + star}
                                        name="rate"
                                        value={star}
                                        onChange={(e) =>
                                            setReviewForm((item) => ({
                                                ...item,
                                                rate: e.target.value,
                                            }))
                                        }
                                    />
                                    <label htmlFor={"star" + star} title="text">
                                        {star} stars
                                    </label>
                                </Fragment>
                            ))}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-light">
                    <Button variant="secondary" onClick={() => setReviewModal(false)}>
                        <Text title="Close" />
                    </Button>
                    <Button variant="primary" onClick={handleReview}>
                        <Text title="SaveChanges" />
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Product;
