import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Text from "../components/common/Text";
import moment from "moment";

export const CartContext = createContext({
    Products: JSON.parse(localStorage.getItem("cart")) || [],
});

export function CartProvider({ children }) {
    const [cart, setCart] = useState([...(moment().diff(moment(localStorage.getItem("cartTimer")), "hours") < 24 ? JSON.parse(localStorage.getItem("cart")) : [] || [])]);
    const [Socket, setSocket] = useState(false);
    const [Updates, setUpdates] = useState(false);

    useEffect(() => {
        if (moment().diff(moment(localStorage.getItem("cartTimer")), "hours") >= 24) {
            localStorage.setItem("cart", JSON.stringify([]));
        }
    }, []);

    const provider = {
        cart,
        useSetCart: (cart) => {
            return setCart(cart);
        },
        Updates,
        useSetUpdates: (updates) => {
            return setUpdates(updates);
        },
        Socket,
        useSetSocket: (socket) => {
            return setSocket(socket);
        },
        useAddProduct: (Product) => {
            let Products = [...cart];
            const theme = localStorage.getItem("theme-mode");
            Products.push(Product);
            localStorage.setItem("cart", JSON.stringify(Products));
            localStorage.setItem("cartTimer", moment().toISOString());
            setCart(Products);
            return toast(<Text title={"productAdded"} />, {
                theme: theme === "dark-mode" ? "dark" : "light",
            });
        },
        useRemoveProduct: (Product) => {
            let Products = [...cart];
            const index = Products.findIndex((product) => product?.id === Product?.id);
            if (index > -1) {
                Products.splice(index, 1);
                localStorage.setItem("cart", JSON.stringify(Products));
                setCart(Products);
                return Products;
            } else return Products;
        },
        useClearCart: () => {
            let Products = [];
            localStorage.setItem("cart", JSON.stringify(Products));
            setCart(Products);
            return Products;
        },
    };
    return <CartContext.Provider value={provider}>{children}</CartContext.Provider>;
}
