import React, { useEffect, useLayoutEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { useTheme } from "../../utils/Hooks";
import { Title } from "../common/Text";

function MenuSwiper({ categories = [], setSelectedCategory, setSubSelectedCategory, selectedCategory = 0 }) {
    const { style } = useTheme();
    const [Direction, setDirection] = useState(style["direction-mode"]);
    const [swiper, setSwiper] = useState(false);

    useLayoutEffect(() => {
        if (style["direction-mode"] !== Direction) {
            swiper.changeLanguageDirection(style["direction-mode"]);
            setDirection(style["direction-mode"]);
        }
    }, [style, Direction, swiper]);

    useEffect(() => {
        if (swiper?.width && Direction) {
            swiper.changeLanguageDirection(style["direction-mode"]);
        }
    }, [swiper, Direction]);

    useEffect(() => {
        if (swiper?.slideTo) swiper?.slideTo(selectedCategory, 1000);
    }, [swiper, selectedCategory]);

    return (
        <div className="row mb-3">
            <div className="col-12 px-2">
                <Swiper onSwiper={(swiper) => setSwiper(swiper)} spaceBetween={15} slidesPerView={"auto"}>
                    {categories?.map((category, index) => (
                        <SwiperSlide key={index}>
                            <div
                                role={"button"}
                                className="card text-center mb-2"
                                style={{ width: "fit-content", margin: "auto" }}
                                onClick={() => {
                                    setSelectedCategory(index);
                                    localStorage.setItem("selectedCategory", index);
                                    setSubSelectedCategory(0);
                                    localStorage.setItem("selectedSubCategory", 0);
                                }}
                            >
                                <div className="card-body p-0">
                                    <img className="avatar avatar-80 rounded-18 coverimg text-center" alt="" src={category?.is_image_url ? category?.image_url : category?.image} />
                                </div>
                            </div>

                            <p className="text-center size-13">
                                <small className={selectedCategory === index ? "text-color-theme font-weight-600" : "text-muted"}>
                                    <Title en={category?.title_en} ar={category?.title_ar} />
                                </small>
                                <br />
                            </p>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default MenuSwiper;
