import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useCart, useTheme } from "../../utils/Hooks";

import Text from "./Text";
import { getQueryParams } from "../../utils/helpers";

function BottomTab({ footerRef }) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const { tenant, branch, table } = params;
    const { style, AddOns } = useTheme();
    const { cart } = useCart();

    const [direction, setDirection] = useState(style["direction-mode"]);
    useEffect(() => {
        setDirection(style["direction-mode"]);
    }, [style]);

    const [Cart, setCart] = useState(cart);
    const [cartUpdate, setCartUpdate] = useState(false);

    useEffect(() => {
        table ? setCartUpdate(cart) : setCart(cart);
    }, [cart]);

    const handleNavigation = async (basePath) => {
        const queryString = await getQueryParams();
        navigate(basePath + (queryString ? `?${queryString}` : ""));
    };

    return (
        <footer className="footer" style={{ ...(AddOns?.["Online Ordering"] ? {} : { width: "100%" }) }} ref={footerRef}>
            <div className="container">
                <ul className="nav nav-pills nav-justified">
                    <li className="nav-item">
                        <button onClick={() => navigate(-1)} disabled={(location.pathname.split("/").length > (table ? 4 : 3) && location.pathname.split("/")[table ? 4 : 3] === "") || location.pathname.split("/").length === (table ? 4 : 3)} className="nav-link">
                            <span>
                                <i className={direction === "ltr" ? "nav-icon bi bi-arrow-left" : "nav-icon bi bi-arrow-right"}></i>
                                <span className="nav-text">
                                    <Text title="Back" />
                                </span>
                            </span>
                        </button>
                    </li>
                    <li className="nav-item centerbutton">
                        <Link to="#" onClick={() => handleNavigation(table ? `/${tenant}/${branch}/${table}/cart` : `/${tenant}/${branch}/cart`)} className="nav-link" id="centermenubtn">
                            <span className="theme-linear-gradient">
                                <i className="bi bi-basket size-22"></i>
                            </span>
                            {table
                                ? cartUpdate?.guests?.reduce((partialSum, a) => partialSum + a?.items?.length, 0) && (
                                      <div className="position-absolute end-0 top-0">
                                          <div className="tag tag-small bg-theme text-white">{cartUpdate?.guests?.reduce((partialSum, a) => partialSum + a?.items?.length, 0)}</div>
                                      </div>
                                  )
                                : Cart?.length > 0 && (
                                      <div className="position-absolute end-0 top-0">
                                          <div className="tag tag-small bg-theme text-white">{Cart?.length}</div>
                                      </div>
                                  )}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="#"
                            onClick={() => handleNavigation(table ? `/${tenant}/${branch}/${table}/` : `/${tenant}/${branch}/`)}
                            className={"nav-link " + (location.pathname.split("/").length > (table ? 4 : 3) && location.pathname.split("/")[table ? 4 : 3] === "" ? "active" : "")}
                        >
                            <span>
                                <i className="nav-icon bi bi-house"></i>
                                <span className="nav-text">
                                    <Text title="Home" />
                                </span>
                            </span>
                        </Link>
                    </li>
                </ul>
            </div>
        </footer>
    );
}

export default BottomTab;
