import React, { useEffect, useState } from "react";
import Container from "../components/common/Container";
import Text from "../components/common/Text";
import { Accordion, Badge, Button, Card, Form, Stack } from "react-bootstrap";
import moment from "moment";
import { useGetCheckAvailability, usePostReservation } from "../utils/API";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguage, useTheme } from "../utils/Hooks";

function Reservation() {
    const params = useParams();
    const navigate = useNavigate();
    const { tenant, branch } = params;
    const { userLanguage } = useLanguage();
    const { AddOns } = useTheme();

    useEffect(() => {
        if (AddOns?.["Reservations"] === false) navigate("/error");
    }, [AddOns?.["Reservations"]]);

    const [Guest, setGuest] = useState(1);
    const [GuestClick, setGuestClick] = useState(false);

    const [Date, setDate] = useState(moment());
    const [DateClick, setDateClick] = useState(false);

    const [Time, setTime] = useState(moment().startOf("hour").add(1, "hour").startOf("hour"));
    const [TimeClick, setTimeClick] = useState(false);

    const [Items, setItems] = useState(false);
    const [Results, setResults] = useState(false);

    const [Step, setStep] = useState(1);

    const numbers = new Array(20).fill(1);

    const roundToNearest15Minutes = (time) => {
        const roundedMinutes = Math.round(time.minute() / 15) * 15;
        return time.clone().minute(roundedMinutes).second(0);
    };

    const { mutate, data, isLoading } = useGetCheckAvailability();

    const handleReservation = () => {
        if (Guest && Date && Time && tenant && branch) {
            const obj = {
                guests_no: Guest,
                reserved_date: Date.format("YYYY-MM-DD"),
                reserved_time: Time.format("HH:mm"),
                tenant_slug: tenant,
                branch_slug: branch,
            };
            setItems(obj);
            mutate(obj);
        }
    };

    useEffect(() => {
        if (data?.data?.responseData) {
            setResults(data?.data?.responseData);
            setStep(2);
        }
    }, [data?.data?.responseData]);

    const { mutate: reserve, data: reserveData, isLoading: isLoadingReserve } = usePostReservation();

    const handleReserve = () => {
        const item = {
            ...Items,
        };
        reserve(item);
    };

    const [ReserveData, setReserveData] = useState(false);

    useEffect(() => {
        if (reserveData?.data?.responseData) {
            setReserveData(reserveData?.data?.responseData);
            setStep(4);
        }
    }, [reserveData?.data?.responseData]);

    return (
        <Container title="Reservations">
            {Step === 1 && (
                <Card className="mt-5">
                    <Card.Header>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <h1 className="display-5 mb-0">
                                    <Text title={`Reservation`} />
                                </h1>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="row mt-1">
                            <div className="col-3 d-flex justify-content-center align-items-center">
                                <Button variant="light" disabled={Guest <= 1} onClick={() => setGuest(Guest - 1)}>
                                    <i className="bi bi-dash fs-1" />
                                </Button>
                            </div>
                            <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                                <Form.Select
                                    id="numbers"
                                    className={`my-2 ${GuestClick ? "d-flex" : "d-none"}`}
                                    value={Guest}
                                    onChange={(e) => {
                                        setGuest(parseInt(e.target.value));
                                        setGuestClick(false);
                                    }}
                                >
                                    {numbers?.map((_, index) => (
                                        <option key={index + 1} value={index + 1}>
                                            {index + 1}
                                        </option>
                                    ))}
                                </Form.Select>
                                <div
                                    className={`${!GuestClick ? "d-flex" : "d-none"} flex-column justify-content-center align-items-center`}
                                    role="button"
                                    onClick={() => {
                                        setGuestClick(true);
                                    }}
                                >
                                    <p className="display-6 fs-2 mb-0">{Guest}</p>
                                    <p className="fs-6 mb-0 text-muted">{Guest > 1 ? <Text title={`guests`} /> : <Text title={`guest`} />}</p>
                                </div>
                            </div>
                            <div className="col-3 d-flex justify-content-center align-items-center">
                                <Button variant="light" disabled={Guest >= 20} onClick={() => setGuest(Guest + 1)}>
                                    <i className="bi bi-plus fs-1" />
                                </Button>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-3 d-flex justify-content-center align-items-center">
                                <Button variant="light" disabled={moment(Date).isSame(moment(), "day")} onClick={() => setDate(moment(Date).add(-1, "d"))}>
                                    <i className="bi bi-dash fs-1" />
                                </Button>
                            </div>
                            <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                                <Form.Control
                                    type="date"
                                    className={`my-2 ${DateClick ? "d-flex" : "d-none"}`}
                                    value={Date.format("YYYY-MM-DD")}
                                    onChange={(e) => {
                                        setDate(moment(e.target.value, "YYYY-MM-DD"));
                                        setDateClick(false);
                                    }}
                                    min={moment().format("YYYY-MM-DD")}
                                ></Form.Control>
                                <div
                                    className={`${!DateClick ? "d-flex" : "d-none"} flex-column justify-content-center align-items-center`}
                                    role="button"
                                    onClick={() => {
                                        setDateClick(true);
                                    }}
                                >
                                    <p className="display-6 fs-3 mb-0">{Date.locale(userLanguage).format("ddd, MMM DD")}</p>
                                    <p className="fs-6 mb-0 text-muted">{<Text title={`date`} />}</p>
                                </div>
                            </div>
                            <div className="col-3 d-flex justify-content-center align-items-center">
                                <Button variant="light" onClick={() => setDate(moment(Date).add(1, "d"))}>
                                    <i className="bi bi-plus fs-1" />
                                </Button>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-3 d-flex justify-content-center align-items-center">
                                <Button variant="light" onClick={() => setTime(moment(Time).add(-15, "minute"))}>
                                    <i className="bi bi-dash fs-1" />
                                </Button>
                            </div>
                            <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                                <Form.Control
                                    type="time"
                                    className={`my-2 ${TimeClick ? "d-flex" : "d-none"}`}
                                    value={Time.format("HH:mm")}
                                    onChange={(e) => {
                                        const selectedTime = moment(e.target.value, "HH:mm");
                                        const roundedTime = roundToNearest15Minutes(selectedTime);
                                        setTime(roundedTime);
                                        setTimeClick(false);
                                    }}
                                    step="900"
                                ></Form.Control>
                                <div
                                    className={`${!TimeClick ? "d-flex" : "d-none"} flex-column justify-content-center align-items-center`}
                                    role="button"
                                    onClick={() => {
                                        setTimeClick(true);
                                    }}
                                >
                                    <p className="display-6 fs-3 mb-0">{Time.locale(userLanguage).format("hh:mm A")}</p>
                                    <p className="fs-6 mb-0 text-muted">{<Text title={`time`} />}</p>
                                </div>
                            </div>
                            <div className="col-3 d-flex justify-content-center align-items-center">
                                <Button variant="light" onClick={() => setTime(moment(Time).add(15, "minute"))}>
                                    <i className="bi bi-plus fs-1" />
                                </Button>
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className="row my-3">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <Button className="w-75 mx-5 py-2" variant="secondary" disabled={isLoading} onClick={handleReservation}>
                                    <h4>
                                        <Text title={isLoading ? `loading...` : `Check`} />
                                    </h4>
                                </Button>
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            )}
            {Step === 2 && (
                <>
                    <Button className="mt-4" variant="light" onClick={() => setStep(1)}>
                        <Text title={`Back`} />
                    </Button>
                    <Card className="my-3">
                        <Card.Header>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-between align-items-center px-4">
                                    <p className="fs-5 mb-0">{moment(Results?.selected_day?.date)?.format("ddd, MMM DD")} </p>
                                    <p className="fs-5 mb-0">
                                        {Items?.guests_no} {Items?.guests_no > 1 ? <Text title={`guests`} /> : <Text title={`guest`} />}
                                    </p>
                                    <p className="fs-5 mb-0"> {moment(Items?.reserved_time, "HH:mm")?.format("hh:mm A")} </p>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <div className="row my-2">
                                {Results?.selected_day?.time_slots?.map(({ slot, is_available }, index) => (
                                    <div className="col-12 my-2 d-flex justify-content-center align-items-center" key={index}>
                                        <Badge
                                            bg={is_available ? "primary" : "secondary"}
                                            style={{ textDecoration: is_available ? "" : "line-through" }}
                                            role="button"
                                            className="fs-4 px-5"
                                            onClick={() => {
                                                if (is_available) {
                                                    setItems((items) => ({
                                                        ...items,
                                                        reserved_time: slot,
                                                        reserved_date: Results?.selected_day?.date,
                                                    }));
                                                    setStep(3);
                                                }
                                            }}
                                        >
                                            {moment(slot, "HH:mm")?.format("hh:mm A")}
                                        </Badge>
                                    </div>
                                ))}
                            </div>
                        </Card.Body>
                    </Card>
                    <Accordion className="my-3" defaultActiveKey="1">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <Text title={`Other dates with availability`} />
                            </Accordion.Header>
                            <Accordion.Body>
                                {Results?.other_days?.map((day, index) => (
                                    <Stack direction="vertical" gap={1} key={index}>
                                        <p className="fs-5 mb-0">{moment(day?.date)?.format("ddd, MMM DD")}</p>
                                        <Stack direction="horizontal" className="d-flex flex-wrap mb-3" gap={2}>
                                            {day?.time_slots?.map(({ slot, is_available }, i) => (
                                                <Badge
                                                    role="button"
                                                    bg={is_available ? "primary" : "secondary"}
                                                    style={{ textDecoration: is_available ? "" : "line-through" }}
                                                    className="fs-6 my-1"
                                                    key={i}
                                                    onClick={() => {
                                                        if (is_available) {
                                                            setItems((items) => ({
                                                                ...items,
                                                                reserved_time: slot,
                                                                reserved_date: day?.date,
                                                            }));
                                                            setStep(3);
                                                        }
                                                    }}
                                                >
                                                    {moment(slot, "HH:mm")?.format("hh:mm A")}
                                                </Badge>
                                            ))}
                                        </Stack>
                                    </Stack>
                                ))}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
            )}
            {Step === 3 && (
                <>
                    <Button className="mt-4" variant="light" onClick={() => setStep(2)}>
                        <Text title={`Back`} />
                    </Button>
                    <Card className="my-3">
                        <Card.Header>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-between align-items-center px-4">
                                    <p className="fs-5 mb-0">{moment(Items?.reserved_date)?.format("ddd, MMM DD")} </p>
                                    <p className="fs-5 mb-0">
                                        {Items?.guests_no} {Items?.guests_no > 1 ? <Text title={`guests`} /> : <Text title={`guest`} />}
                                    </p>
                                    <p className="fs-5 mb-0"> {moment(Items?.reserved_time, "HH:mm")?.format("hh:mm A")} </p>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <div className="row my-2">
                                <div className="col-6">
                                    <Form.Label htmlFor="first_name">
                                        <Text title="first_name" />
                                        <span className="text-danger"> *</span>
                                    </Form.Label>
                                    <Form.Control id="first_name" type="text" className="mb-2" value={Items?.first_name} onChange={(e) => setItems((item) => ({ ...item, first_name: e.target.value }))} />
                                </div>
                                <div className="col-6">
                                    <Form.Label htmlFor="last_name">
                                        <Text title="last_name" />
                                        <span className="text-danger"> *</span>
                                    </Form.Label>
                                    <Form.Control id="last_name" type="text" className="mb-2" value={Items?.last_name} onChange={(e) => setItems((item) => ({ ...item, last_name: e.target.value }))} />
                                </div>
                                <div className="col-12">
                                    <Form.Label htmlFor="email">
                                        <Text title="email" />
                                        <span className="text-danger"> *</span>
                                    </Form.Label>
                                    <Form.Control id="email" type="text" className="mb-2" value={Items?.email} onChange={(e) => setItems((item) => ({ ...item, email: e.target.value }))} />
                                </div>
                                <div className="col-12">
                                    <Form.Label htmlFor="mobile">
                                        <Text title="mobile" />
                                        <span className="text-danger"> *</span>
                                    </Form.Label>
                                    <Form.Control id="mobile" type="text" className="mb-2" value={Items?.mobile} onChange={(e) => setItems((item) => ({ ...item, mobile: e.target.value }))} />
                                </div>
                                <div className="col-12">
                                    <Form.Label htmlFor="notes">
                                        <Text title="notes" />
                                    </Form.Label>
                                    <Form.Control id="notes" type="text" as="textarea" rows={3} className="mb-2" value={Items?.notes} onChange={(e) => setItems((item) => ({ ...item, notes: e.target.value }))} />
                                </div>
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            <div className="row my-3">
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <Button className="w-75 mx-5 py-2" variant="secondary" disabled={isLoadingReserve || !Items?.first_name || !Items?.last_name || !Items?.email || !Items?.mobile} onClick={handleReserve}>
                                        <Text title={isLoadingReserve ? `loading...` : `Book`} />
                                    </Button>
                                </div>
                            </div>
                        </Card.Footer>
                    </Card>
                </>
            )}
            {Step === 4 && (
                <>
                    <Button className="mt-4" variant="light" onClick={() => setStep(1)}>
                        <Text title={`Back`} />
                    </Button>
                    <Card className="my-3">
                        <Card.Header>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-between align-items-center px-4">
                                    <p className="fs-5 mb-0 text-muted">
                                        <Text title={`Reservation booked successfully!`} />
                                    </p>
                                    <i className="bi-check2-circle fs-1 text-success" />
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <div className="row my-2">
                                <div className="col-12 col-md-6 mb-3 d-flex justify-content-between align-items-center">
                                    <Form.Label className="mb-0 text-muted" htmlFor="reservation_no">
                                        <Text title="reservation_no" />
                                    </Form.Label>
                                    <p className="fs-5">{ReserveData?.reservation_no}</p>
                                </div>
                                <div className="col-12 col-md-6 mb-3 d-flex justify-content-between align-items-center">
                                    <Form.Label className="mb-0 text-muted" htmlFor="create_at">
                                        <Text title="create_at" />
                                    </Form.Label>
                                    <p className="fs-5">{moment(ReserveData?.create_at).format("DD-MM-YYYY hh:mm a")}</p>
                                </div>
                                <div className="col-12 col-md-6 mb-3 d-flex justify-content-between align-items-center">
                                    <Form.Label className="mb-0 text-muted" htmlFor="guests_no">
                                        <Text title="guests_no" />
                                    </Form.Label>
                                    <p className="fs-5">{ReserveData?.guests_no}</p>
                                </div>
                                <div className="col-12 col-md-6 mb-3 d-flex justify-content-between align-items-center">
                                    <Form.Label className="mb-0 text-muted" htmlFor="reserved_date">
                                        <Text title="reserved_date" />
                                    </Form.Label>
                                    <p className="fs-5">{moment(ReserveData?.reserved_date).format("DD-MM-YYYY")}</p>
                                </div>
                                <div className="col-12 col-md-6 mb-3 d-flex justify-content-between align-items-center">
                                    <Form.Label className="mb-0 text-muted" htmlFor="reserved_time">
                                        <Text title="reserved_time" />
                                    </Form.Label>
                                    <p className="fs-5">{moment(ReserveData?.reserved_time, "HH:mm:ss").format("hh:mm a")}</p>
                                </div>
                                <div className="col-12 col-md-6 mb-3 d-flex justify-content-between align-items-center">
                                    <Form.Label className="mb-0 text-muted" htmlFor="email">
                                        <Text title="email" />
                                    </Form.Label>
                                    <p className="fs-5">{ReserveData?.email}</p>
                                </div>
                                <div className="col-12 col-md-6 mb-3 d-flex justify-content-between align-items-center">
                                    <Form.Label className="mb-0 text-muted" htmlFor="first_name">
                                        <Text title="first_name" />
                                    </Form.Label>
                                    <p className="fs-5">{ReserveData?.first_name}</p>
                                </div>
                                <div className="col-12 col-md-6 mb-3 d-flex justify-content-between align-items-center">
                                    <Form.Label className="mb-0 text-muted" htmlFor="last_name">
                                        <Text title="last_name" />
                                    </Form.Label>
                                    <p className="fs-5">{ReserveData?.last_name}</p>
                                </div>
                                <div className="col-12 col-md-6 mb-3 d-flex justify-content-between align-items-center">
                                    <Form.Label className="mb-0 text-muted" htmlFor="mobile">
                                        <Text title="mobile" />
                                    </Form.Label>
                                    <p className="fs-5">{ReserveData?.mobile}</p>
                                </div>
                                {ReserveData?.notes && (
                                    <div className="col-12 mb-3 d-flex justify-content-between align-items-center">
                                        <Form.Label className="mb-0 text-muted" htmlFor="notes">
                                            <Text title="notes" />
                                        </Form.Label>
                                        <p className="fs-5">{ReserveData?.notes}</p>
                                    </div>
                                )}
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            <div className="row my-3">
                                <div className="col-12 d-flex justify-content-center align-items-center px-4">
                                    <p className="fs-6 mb-0 text-muted">
                                        <Text title={`(take screenshot to save your appointment)*`} />
                                    </p>
                                </div>
                            </div>
                        </Card.Footer>
                    </Card>
                </>
            )}
        </Container>
    );
}

export default Reservation;
