import { createBrowserRouter } from "react-router-dom";

import Landing from "../pages/Landing";
import Home from "../pages/Home";
import Styles from "../pages/Styles";
import Product from "../pages/Product";
import Cart from "../pages/Cart";
import ContactUs from "../pages/ContactUs";
import TermsConditions from "../pages/TermsConditions";
import AboutUs from "../pages/AboutUs";
import Reservation from "../pages/Reservation";
import TrackOrder from "../pages/TrackOrder";
import PlaceOrder from "../pages/PlaceOrder";

import Splash from "../pages/Splash";
import P404 from "../pages/404";
import Error from "../pages/Error";

import RecipeDetails from "../pages/RecipeDetails";
import Games from "../pages/Games";
import Plays from "../pages/plays";
import Offers from "../pages/Offers";
import Menja from "../pages/Games/MENJA/index";
import Coloren from "../pages/Games/Coloren";
import ExpirePage from "../pages/ExpirePage";
import Recipe from "../pages/Recipe";
import CatgoriesPage from "../pages/Categories";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Splash />,
    },
    {
        path: "/:tenant",
        element: <Landing />,
    },
    {
        path: "/:tenant/:branch/landing",
        element: <Landing />,
    },
    {
        path: "/:tenant/:branch",
        element: <Home />,
        errorElement: <Error />,
    },
    {
        path: "/:tenant/:branch/:table",
        element: <Home />,
        errorElement: <Error />,
    },
    {
        path: "/:tenant/:branch/styles",
        element: <Styles />,
        errorElement: <Error path="/styles" />,
    },
    {
        path: "/:tenant/:branch/cart",
        element: <Cart />,
        errorElement: <Error path="/cart" />,
    },
    {
        path: "/:tenant/:branch/:table/cart",
        element: <Cart />,
        errorElement: <Error path="/cart" />,
    },
    {
        path: "/:tenant/:branch/product/:id",
        element: <Product />,
        errorElement: <Error path="/product/:id" />,
    },
    {
        path: "/:tenant/:branch/:table/product/:id",
        element: <Product />,
        errorElement: <Error path="/product/:id" />,
    },
    {
        path: "/:tenant/:branch/contactUs",
        element: <ContactUs />,
    },
    {
        path: "/:tenant/:branch/:table/contactUs",
        element: <ContactUs />,
    },
    {
        path: "/:tenant/:branch/termsConditions",
        element: <TermsConditions />,
    },
    {
        path: "/:tenant/:branch/:table/termsConditions",
        element: <TermsConditions />,
    },
    {
        path: "/:tenant/:branch/aboutUs",
        element: <AboutUs />,
    },
    {
        path: "/:tenant/:branch/:table/aboutUs",
        element: <AboutUs />,
    },
    {
        path: "/:tenant/:branch/reservation",
        element: <Reservation />,
    },
    {
        path: "/:tenant/:branch/place-order",
        element: <PlaceOrder />,
    },
    {
        path: "/:tenant/:branch/order-details/:id",
        element: <TrackOrder />,
    },
    {
        path: "/:tenant/:branch/plays",
        element: <Plays />,
    },
    {
        path: "/:tenant/:branch/:table/plays",
        element: <Plays />,
    },
    {
        path: "/:tenant/:branch/offers",
        element: <Offers />,
    },
    {
        path: "/:tenant/:branch/:table/offers",
        element: <Offers />,
    },
    {
        path: "/:tenant/:branch/recipes",
        element: <Recipe />,
        errorElement: <Error />,
    },
    {
        path: "/:tenant/:branch/:table/recipes",
        element: <Recipe />,
        errorElement: <Error />,
    },
    {
        path: "/:tenant/:branch/recipes/:id",
        element: <CatgoriesPage />,
        errorElement: <Error />,
    },
    {
        path: "/:tenant/:branch/:table/recipes/:id",
        element: <CatgoriesPage />,
        errorElement: <Error />,
    },
    {
        path: "/:tenant/:branch/recipes/:category/:id",
        element: <RecipeDetails />,
        errorElement: <Error />,
    },
    {
        path: "/:tenant/:branch/:table/recipes/:category/:id",
        element: <RecipeDetails />,
        errorElement: <Error />,
    },
    {
        path: "/flappy",
        element: <Games />,
    },
    {
        path: "/coloren",
        element: <Coloren />,
    },
    {
        path: "/menja",
        element: <Menja />,
    },
    {
        path: "/expired",
        element: <ExpirePage />,
    },
    {
        path: "/error",
        element: <P404 />,
    },
    {
        path: "*",
        element: <P404 />,
    },
]);
export default router;
