import { useLayoutEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { LanguageProvider } from "./Resources/Language";
import SocketLayer from "./components/common/SocketLayer";
import DynamicManifest from "./components/common/DynamicManifest";
import { useTheme } from "./utils/Hooks";
import Router from "./utils/Router";
import CheckExpired from "./components/common/CheckExpired";

function App() {
    const { loadStyle } = useTheme();
    const [disable, setDisable] = useState(false);

    useLayoutEffect(() => {
        loadStyle();
    }, [loadStyle]);

    return (
        <LanguageProvider>
            <CheckExpired disable={disable} setDisable={setDisable} />
            {disable ? null : <RouterProvider router={Router} />}
            <SocketLayer />
            <ToastContainer />
            <DynamicManifest />
        </LanguageProvider>
    );
}

export default App;
