import React, { useEffect, useState } from "react";
import { useTheme } from "../../utils/Hooks";

function ColorRow() {
  const { style, styleChange } = useTheme();
  const [Color, setColor] = useState(style["color-mode"]);
  useEffect(() => {
    const color = style["color-mode"];
    if (Color !== color) {
      styleChange("color-mode", Color);
    }
  }, [Color, style, styleChange]);
  const changeColor = (e) => {
    e.preventDefault();
    setColor(e.target.value);
  };
  return (
    <div className="row mb-3">
      <div className="col-12">
        <input
          type="radio"
          className="btn-check mb-2"
          name="color-scheme"
          id="btn-color-blue"
          value="theme-blue"
          onClick={changeColor}
        />
        <label
          className="btn bg-blue shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white"
          htmlFor="btn-color-blue"
        >
          BL
        </label>

        <input
          type="radio"
          className="btn-check mb-2"
          name="color-scheme"
          id="btn-color-indigo"
          value="theme-indigo"
          onClick={changeColor}
        />
        <label
          className="btn bg-indigo shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white"
          htmlFor="btn-color-indigo"
        >
          IN
        </label>

        <input
          type="radio"
          className="btn-check mb-2"
          name="color-scheme"
          id="btn-color-purple"
          value="theme-purple"
          onClick={changeColor}
        />
        <label
          className="btn bg-purple shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white"
          htmlFor="btn-color-purple"
        >
          PL
        </label>

        <input
          type="radio"
          className="btn-check mb-2"
          name="color-scheme"
          id="btn-color-pink"
          value="theme-pink"
          onClick={changeColor}
        />
        <label
          className="btn bg-pink shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white"
          htmlFor="btn-color-pink"
        >
          PK
        </label>

        <input
          type="radio"
          className="btn-check mb-2"
          name="color-scheme"
          id="btn-color-red"
          value="theme-red"
          onClick={changeColor}
        />
        <label
          className="btn bg-red shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white"
          htmlFor="btn-color-red"
        >
          RD
        </label>

        <input
          type="radio"
          className="btn-check mb-2"
          name="color-scheme"
          id="btn-color-orange"
          value="theme-orange"
          onClick={changeColor}
        />
        <label
          className="btn bg-orange shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white"
          htmlFor="btn-color-orange"
        >
          OG
        </label>

        <input
          type="radio"
          className="btn-check mb-2"
          name="color-scheme"
          id="btn-color-yellow"
          value="theme-yellow"
          onClick={changeColor}
        />
        <label
          className="btn bg-yellow shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white"
          htmlFor="btn-color-yellow"
        >
          YL
        </label>

        <input
          type="radio"
          className="btn-check mb-2"
          name="color-scheme"
          id="btn-color-green"
          value="theme-green"
          onClick={changeColor}
        />
        <label
          className="btn bg-green shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white"
          htmlFor="btn-color-green"
        >
          GN
        </label>

        <input
          type="radio"
          className="btn-check mb-2"
          name="color-scheme"
          id="btn-color-teal"
          value="theme-teal"
          onClick={changeColor}
        />
        <label
          className="btn bg-teal shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white"
          htmlFor="btn-color-teal"
        >
          TL
        </label>

        <input
          type="radio"
          className="btn-check mb-2"
          name="color-scheme"
          id="btn-color-cyan"
          value="theme-cyan"
          onClick={changeColor}
        />
        <label
          className="btn bg-cyan shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white"
          htmlFor="btn-color-cyan"
        >
          CN
        </label>
      </div>
    </div>
  );
}

export default ColorRow;
