import React, { useEffect, useState } from "react";
import { useTheme } from "../../utils/Hooks";
import Text from "../common/Text";

import darkBg1 from "../../assets/img/darkbg-1.png";
import darkBg2 from "../../assets/img/darkbg-2.png";
import darkBg3 from "../../assets/img/darkbg-3.png";

function BgImageRow() {
  const { style, styleChange } = useTheme();
  const [bgImage, setBgImage] = useState(style["bgImage-mode"]);
  useEffect(() => {
    const image = style["bgImage-mode"];
    if (bgImage !== image) {
      styleChange("bgImage-mode", bgImage);
    }
  }, [bgImage, style, styleChange]);
  return (
    <div className="row ">
      <div className="col mb-4">
        <input
          type="radio"
          className="btn-check"
          name="background-select"
          id="btn-bg1"
          value="bg-1"
          checked={bgImage === "bg-1"}
          onChange={() => setBgImage("bg-1")}
        />
        <label
          className="btn btn-outline-primary background-btn p-1 text-center"
          htmlFor="btn-bg1"
        >
          <img
            src={darkBg1}
            alt="darkBg1"
            className="mw-100 border rounded-15"
          />
          <br />
          <span className="py-2 d-block small">
            <Text title="Geometric" />
          </span>
        </label>
      </div>
      <div className="col mb-4">
        <input
          type="radio"
          className="btn-check"
          name="background-select"
          id="btn-bg2"
          value="bg-2"
          checked={bgImage === "bg-2"}
          onChange={() => setBgImage("bg-2")}
        />
        <label
          className="btn btn-outline-primary background-btn p-1 text-center"
          htmlFor="btn-bg2"
        >
          <img
            src={darkBg2}
            alt="darkBg2"
            className="mw-100 border rounded-15"
          />
          <br />
          <span className="py-2 d-block small">
            <Text title="Modern" />
          </span>
        </label>
      </div>
      <div className="col mb-4">
        <input
          type="radio"
          className="btn-check"
          name="background-select"
          id="btn-bg3"
          value="bg-3"
          checked={bgImage === "bg-3"}
          onChange={() => setBgImage("bg-3")}
        />
        <label
          className="btn btn-outline-primary background-btn p-1 text-center"
          htmlFor="btn-bg3"
        >
          <img
            src={darkBg3}
            alt="darkBg3"
            className="mw-100 border rounded-15"
          />
          <br />
          <span className="py-2 d-block small">
            <Text title="Bubble" />
          </span>
        </label>
      </div>
    </div>
  );
}

export default BgImageRow;
