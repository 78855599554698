import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Text from "../../components/common/Text";
import { getQueryParams } from "../../utils/helpers";

function EmptyCart({ tenant, branch, table }) {
    const navigate = useNavigate();

    const handleNavigation = async () => {
        const queryString = await getQueryParams();
        const basePath = table ? `/${tenant}/${branch}/${table}/` : `/${tenant}/${branch}/`;
        navigate(basePath + (queryString ? `?${queryString}` : ""));
    };

    return (
        <div className="row" style={{ height: "75vh" }}>
            <div className="col-12 h-flex py-3 position-relative" style={{ height: "450px" }}>
                <div className="coverimg h-50 w-100 position-absolute top-0 start-0"></div>
                <div className="row h-75 justify-content-center">
                    <div className="col-10 col-md-6 col-lg-4 align-self-end text-center mb-4">
                        <i className="bi bi-emoji-frown color-primary" style={{ fontSize: 100 }} />
                        <p className="text-secondary pt-3">
                            <Text title="NoContent" />
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-11 col-sm-11 mx-auto mb-4 align-self-end">
                <div className="row ">
                    <div className="col-12 d-grid">
                        <Link to={"#"} onClick={handleNavigation} className="btn btn-default btn-lg shadow-sm btn-rounded">
                            <Text title="BackHome" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmptyCart;
