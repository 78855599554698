import React, { useEffect, useLayoutEffect, useState } from "react";
import Container from "../components/common/Container";

import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
function plays() {
    return (
        <>
            <Container title="Play">
                <Row className="mt-5">
                    <Col
                        xs={6}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <a
                            href="/flappy"
                            role={"button"}
                            className="card text-center m-1 mb-2"
                            style={{
                                width: "fit-content",
                                margin: "auto",
                                borderRadius: "10px",
                            }}
                        >
                            <div className="card-body p-2">
                                <img
                                    src={require("../assets/img/flappy.png")}
                                    className="avatar avatar-80  coverimg text-center"
                                    style={{
                                        borderRadius: "10px",
                                        width: "auto",
                                        height: "130px",
                                    }}
                                />
                            </div>
                        </a>
                        <h3>Flappy</h3>
                    </Col>
                    <Col
                        xs={6}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <a
                            href="/coloren"
                            role={"button"}
                            className="card text-center m-1 mb-2"
                            style={{
                                width: "fit-content",
                                margin: "auto",
                                borderRadius: "10px",
                            }}
                        >
                            <div className="card-body p-2">
                                <img
                                    src={require("../assets/img/coloren.png")}
                                    className="avatar avatar-80  coverimg text-center"
                                    style={{
                                        borderRadius: "10px",
                                        width: "auto",
                                        height: "130px",
                                    }}
                                />
                            </div>
                        </a>
                        <h3>Coloren</h3>
                    </Col>
                    <Col
                        xs={6}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <a
                            href="/menja"
                            role={"button"}
                            className="card text-center m-1 mb-2"
                            style={{
                                width: "fit-content",
                                margin: "auto",
                                borderRadius: "10px",
                            }}
                        >
                            <div className="card-body p-2">
                                <img
                                    src={require("../assets/img/menja.jpeg")}
                                    className="avatar avatar-80  coverimg text-center"
                                    style={{
                                        borderRadius: "10px",
                                        width: "auto",
                                        height: "130px",
                                    }}
                                />
                            </div>
                        </a>
                        <h3>MENJA</h3>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default plays;
