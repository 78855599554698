import React, { useEffect, useState } from "react";

import MenuSwiper from "./MenuSwiper";
import SubMenuSwiper from "./SubMenuSwiper";
import Products from "./Products";

import Container from "../common/Container";
import { useGetMenu } from "../../utils/API";

import Error from "../../pages/Error";

function Main() {
  const [openGuestModal, setOpenGuestModal] = useState(false);

  const { isLoading, error, data } = useGetMenu();
  const [selectedCategory, setSelectedCategory] = useState(
    parseInt(localStorage.getItem("selectedCategory") || 0)
  );
  const [selectedSubCategory, setSubSelectedCategory] = useState(
    parseInt(localStorage.getItem("selectedSubCategory") || 0)
  );

  const [SearchQuery, setSearchQuery] = useState("");

  const [categoryProducts, setCategoryProducts] = useState([]);

  const [SearchProducts, setSearchProducts] = useState([]);

  useEffect(() => {
    if (selectedCategory <= data?.categories?.length) {
      if (data?.categories[selectedCategory]?.show_subcategories === false) {
        let categoryProducts = [];
        data?.categories[selectedCategory]?.subcategories.forEach(
          (subcategory) => {
            categoryProducts = [...categoryProducts, ...subcategory.products];
          }
        );
        setCategoryProducts(categoryProducts);
      } else {
        setCategoryProducts([]);
      }
    }
  }, [selectedCategory, data?.categories]);

  useEffect(() => {
    if (SearchQuery) {
      let SearchProducts = [];
      data?.categories.forEach((category) => {
        category.subcategories.forEach((subcategory) => {
          subcategory.products.forEach((product) => {
            if (
              product?.title_en
                .toLowerCase()
                .includes(SearchQuery.toLowerCase()) ||
              product?.title_ar?.includes(SearchQuery)
            )
              SearchProducts.push(product);
          });
        });
      });
      setSearchProducts(SearchProducts);
    } else {
      setSearchProducts([]);
    }
  }, [SearchQuery, data?.categories]);

  if (error&&!isLoading) return <Error error={error} />;
  
  return (
    <Container
      title="Home"
      isLoading={isLoading}
      viewSearch={true}
      viewBottomTab={true}
      openGuestModal={openGuestModal}
      SearchQuery={SearchQuery}
      setSearchQuery={setSearchQuery}
      viewAlert={true}
    >
      {SearchQuery === "" && (
        <MenuSwiper
          categories={data?.categories}
          setSelectedCategory={setSelectedCategory}
          setSubSelectedCategory={setSubSelectedCategory}
          selectedCategory={selectedCategory}
        />
      )}
      {SearchQuery === "" &&
        data?.categories?.length > 0 &&
        data?.categories[selectedCategory]?.show_subcategories && (
          <SubMenuSwiper
            subCategories={data?.categories[selectedCategory]?.subcategories}
            setSubSelectedCategory={setSubSelectedCategory}
            selectedSubCategory={selectedSubCategory}
          />
        )}
      {data?.categories?.length > 0 &&
        data?.categories[selectedCategory]?.subcategories.length > 0 && (
          <Products
            SearchQuery={SearchQuery}
            setOpenGuestModal={setOpenGuestModal}
            categories={data?.categories[selectedCategory]}
            subCategories={data?.categories[selectedCategory]?.subcategories[selectedSubCategory]}
            products={
              SearchQuery === ""
                ? categoryProducts.length > 0
                  ? categoryProducts
                  : data?.categories[selectedCategory]?.subcategories[
                      selectedSubCategory
                    ]?.products
                : SearchProducts
            }
          />
        )}
    </Container>
  );
}
export default Main;
