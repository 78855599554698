import { useEffect, useRef, useState } from "react";
import { useCart, useGuest, useLanguage } from "../../utils/Hooks";

function SocketLayer() {
    const { userLanguage } = useLanguage();
    const tenant = window.location.pathname.split("/")[1];
    const table = window.location.pathname.split("/")[3];
    const { useSetCart, useSetSocket, Socket, useSetUpdates } = useCart();
    const { guest } = useGuest();

    const [socketUpdate, setSocketUpdate] = useState(false);
    const socketRef = useRef(null);
    const [socketLoading, setSocketLoading] = useState(true);

    useEffect(() => {
        if (Socket && userLanguage && !socketLoading) {
            if (Socket?.OPEN && Socket?.readyState === WebSocket.OPEN) {
                Socket?.send(
                    JSON.stringify({
                        action: "get_cart",
                        language: userLanguage,
                        payload: {},
                    })
                );
            }
        }
    }, [userLanguage, Socket, socketLoading]);

    useEffect(() => {
        if (table && guest && !socketUpdate && !socketRef.current) {
            SocketConnect();
        }
    }, [table, guest, socketUpdate, socketRef.current]);

    const SocketConnect = () => {
        let ws = new WebSocket(`wss://${process.env.REACT_APP_BASEURL}/ws/${tenant}/${table}/?name=${guest?.name}&mobile=${guest?.mobile}&is_anonymous=${guest?.is_anonymous}`);

        ws.onopen = () => {
            setTimeout(() => {
                setSocketLoading(false);
            }, 1000);
            ws.readyState === WebSocket.OPEN
                ? ws.send(
                      JSON.stringify({
                          action: "get_cart",
                          language: userLanguage,
                          payload: {},
                      })
                  )
                : null;
        };

        ws.onmessage = (e) => {
            let jsonData = JSON.parse(e.data);
            socketSignalsHandler(jsonData);
            setSocketUpdate(true);
        };
        socketRef.current = ws;
        useSetSocket(ws);
        ws.onclose = (e) => {
            if (table !== "reservation")
                setTimeout(() => {
                    SocketConnect();
                }, 1000);
        };
        ws.onerror = (err) => {
            console.error("Socket encountered error: ", err.message, "Closing socket");
            ws.close();
        };
    };

    const socketSignalsHandler = (socketUpdate) => {
        if (socketUpdate.cart_update) {
            useSetCart(socketUpdate.cart_update);
        } else if (socketUpdate.order_updated) {
            if (socketUpdate.order_updated?.is_closed) socketRef.current.close();
            useSetUpdates(socketUpdate.order_updated);
        } else if (socketUpdate.item_updated) {
            useSetCart((prevCart) => {
                const cart = { ...prevCart };
                const { guest_id, item_id, is_owner } = socketUpdate.item_updated;
                const guestExist = cart.guests.some((guest) => guest?.guest_id === guest_id);
                if (guestExist) {
                    cart.guests = cart.guests.map((guest) => {
                        const updatedGuest = { ...guest };
                        const itemIndex = updatedGuest.items.findIndex((item) => item.item_id === item_id);
                        if (itemIndex !== -1) {
                            updatedGuest.items = updatedGuest.items.map((item, index) =>
                                index === itemIndex
                                    ? {
                                          ...item,
                                          ...socketUpdate.item_updated,
                                          product_image: item.product_image, // Added this to avoid image flicker on every item update
                                          modifiers: socketUpdate.item_updated.modifiers || item.modifiers,
                                      }
                                    : item
                            );
                        }
                        return updatedGuest;
                    });
                } else {
                    if (is_owner) {
                        cart.guests = [
                            {
                                guest_id: guest_id,
                                name: socketUpdate.item_updated.guest_name,
                                items: [{ ...socketUpdate.item_updated }],
                            },
                            ...cart.guests,
                        ];
                    } else {
                        cart.guests = [
                            ...cart.guests,
                            {
                                guest_id: guest_id,
                                name: socketUpdate.item_updated.guest_name,
                                items: [{ ...socketUpdate.item_updated }],
                            },
                        ];
                    }
                }
                return cart;
            });
        } else if (socketUpdate.item_created) {
            useSetCart((prevCart) => {
                const cart = { ...prevCart };
                const { guest_id, is_owner } = socketUpdate.item_created;
                const guestExist = cart.guests.some((guest) => guest?.guest_id === guest_id);
                if (guestExist) {
                    cart.guests = cart.guests.map((guest) => {
                        if (guest.guest_id === guest_id) {
                            const updatedGuest = { ...guest };
                            updatedGuest.items = [socketUpdate.item_created, ...updatedGuest.items];
                            return updatedGuest;
                        }
                        return guest;
                    });
                } else {
                    if (is_owner) {
                        cart.guests = [
                            {
                                guest_id: guest_id,
                                name: socketUpdate.item_created.guest_name,
                                items: [{ ...socketUpdate.item_created }],
                            },
                            ...cart.guests,
                        ];
                    } else {
                        cart.guests = [
                            ...cart.guests,
                            {
                                guest_id: guest_id,
                                name: socketUpdate.item_created.guest_name,
                                items: [{ ...socketUpdate.item_created }],
                            },
                        ];
                    }
                }
                return cart;
            });
        } else if (socketUpdate.item_deleted) {
            useSetCart((prevCart) => {
                const cart = { ...prevCart };
                cart.guests = cart.guests.map((guest) => {
                    const updatedGuest = { ...guest };
                    const itemIndex = updatedGuest.items.findIndex((item) => item.item_id === socketUpdate?.item_deleted?.item_id);
                    if (itemIndex !== -1) {
                        updatedGuest.items = [...updatedGuest.items.slice(0, itemIndex), ...updatedGuest.items.slice(itemIndex + 1)];
                    }
                    return updatedGuest;
                });
                return cart;
            });
        }
    };

    return null;
}

export default SocketLayer;
