import React from "react";
import Text from "../components/common/Text";
import Container from "../components/common/Container";

function ExpirePage() {
    return (
        <Container title="Expired">
            <div className="row h-100">
                <div className="col-12 h-flex py-3 position-relative" style={{ height: "522px" }}>
                    <div className="row h-100 justify-content-center">
                        <div className="col-12 align-self-end text-center mb-4">
                            <i className="bi bi-qr-code-scan mx-auto mw-100 text-secondary rounded" style={{ fontSize: 186 }}></i>
                            <p className="text-secondary mt-5 fs-5">
                                <Text title="PExpired" />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default ExpirePage;
