import React from "react";

// import onPixie from "../assets/img/Onpixie.png";
import Grip from "../assets/img/Grip.svg";
import splash from "../assets/img/splash.jpg";
import Copyright from "../components/common/Copyright";

function Splash() {
    return (
        <div className="body-scroll d-flex flex-column h-100 bg-theme transform-page-scale" data-page="splash">
            <main className="container-fluid h-100 py-0 theme-light-radial-gradient">
                <img className="coverimg h-100 w-100 position-absolute top-0 start-0" alt="" src={splash} />
                <div className="row bg-theme-round-opac-box" style={{ height: "400px" }}>
                    <div className="col-10 col-md-6 col-lg-4 text-center align-self-center mx-auto">
                        <div className="text-white">
                            <img src={Grip} alt="Grip" height={70} />
                            <h1 className="mt-4 mb-3">
                                <span className="">QR Menu</span>
                                <br />
                                <span className="fw-light text-uppercase">Foodies</span>
                            </h1>
                            <Copyright />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Splash;
