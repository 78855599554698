import React, { useEffect, useState } from "react";
import { useTheme } from "../../utils/Hooks";
import Text from "../common/Text";

function ThemeRow() {
  const { styleChange, style } = useTheme();
  const [Theme, setTheme] = useState(style["theme-mode"]);

  useEffect(() => {
    setTheme(style["theme-mode"]);
  }, [style]);

  const changeTheme = () => {
    const theme = Theme === "light-mode" ? "dark-mode" : "light-mode";
    styleChange("theme-mode", theme);
  };

  return (
    <div className="row mb-4">
      <div className="col-6 d-grid">
        <input
          type="radio"
          className="btn-check"
          name="theme-mode"
          id="btn-layout-modes-light"
          checked={Theme === "light-mode"}
          onChange={changeTheme}
        />
        <label
          className="btn btn-outline-warning shadow-sm "
          htmlFor="btn-layout-modes-light"
        >
          <i className="bi bi-sun fs-4 mb-2 d-block"></i>
          <Text title="LightMode" />
        </label>
      </div>
      <div className="col-6 d-grid">
        <input
          type="radio"
          className="btn-check"
          name="theme-mode"
          id="btn-layout-modes-dark"
          checked={Theme === "dark-mode"}
          onChange={changeTheme}
        />
        <label
          className="btn btn-outline-dark shadow-sm bg-dark text-white"
          htmlFor="btn-layout-modes-dark"
        >
          <i className="bi bi-moon-stars fs-4 mb-2 d-block"></i>
          <Text title="DarkMode" />
        </label>
      </div>
    </div>
  );
}

export default ThemeRow;
