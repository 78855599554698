import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetRecipesCategoriesById } from "../../utils/API";
import Container from "../common/Container";
import NoContent from "../common/NoContent";
import { getQueryParams } from "../../utils/helpers";

function Categories() {
    const navigate = useNavigate();
    const { tenant, branch, id, table } = useParams();
    const { isLoading, data } = useGetRecipesCategoriesById(id);
    const [AllRecipes, setAllRecipes] = useState([]);
    const [SearchQuery, setSearchQuery] = useState("");

    const toProduct = async (id) => {
        const queryString = await getQueryParams();
        const basePath = table ? `/${tenant}/${branch}/${table}/recipes/${id}` : `/${tenant}/${branch}/recipes/${id}`;
        navigate(basePath + (queryString ? `?${queryString}` : ""));
    };

    useEffect(() => {
        setAllRecipes(data?.results);
    }, [data]);
    useEffect(() => {
        if (SearchQuery) {
            let SearchProducts = [];
            data?.results.forEach((item) => {
                if (item?.title_en.toLowerCase().includes(SearchQuery.toLowerCase()) || item?.title_ar?.includes(SearchQuery)) SearchProducts.push(item);
            });
            setAllRecipes(SearchProducts);
        } else {
            setAllRecipes(data?.results);
        }
    }, [SearchQuery, data?.results]);
    if (data?.results === null)
        return (
            <Container title="Contact Us">
                <NoContent />
            </Container>
        );
    return (
        <Container isLoading={isLoading} viewSearch={true} SearchQuery={SearchQuery} setSearchQuery={setSearchQuery} title="Recipes " viewBottomTab={true}>
            <div className="row mb-4 g-0 ">
                <div className="col-12">
                    {AllRecipes?.map((item, i) => {
                        return (
                            <>
                                <div className="card mt-2">
                                    <div className="card-body p-0">
                                        <div className={`row mx-0 border-top`}>
                                            <div className="col-12 col-md-4 col-lg-3 border-end p-3" onClick={() => toProduct(item?.id)}>
                                                <div className="position-relative">
                                                    <div className="position-absolute end-0 top-0 z-index-1 ">
                                                        <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-danger">
                                                            <i className="bi bi-heart size-10 vm"></i>
                                                        </button>
                                                    </div>
                                                    <figure className="avatar w-100 rounded-15  mb-3 coverimg">
                                                        <img src={item?.image} alt="" className="w-100" />
                                                    </figure>

                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="small">
                                                                <small className="text-muted size-12">{item?.title}</small>
                                                            </p>
                                                        </div>
                                                        <div className="col-auto px-0">
                                                            <button className="btn btn-sm btn-link text-color-theme">
                                                                <i className="bi bi-bag-plus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </div>
        </Container>
    );
}

export default Categories;
