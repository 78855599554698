import React, { useState } from "react";
import Container from "../components/common/Container";

import { Col, Row } from "react-bootstrap";
import NoContent from "../components/common/NoContent";
import youtubeIcon from "../assets/img/youtube.svg";
import Modal from "../components/common/AdsModal";
import { useTheme } from "../utils/Hooks";
import { useGetAds } from "../utils/API";

function Offers() {
    const { Ads } = useTheme();
    const { isLoading } = useGetAds();
    const [show, setShow] = useState(false);
    const [selectedObj, setSelectedObj] = useState(null);

    const handleOnCardClick = (item) => {
        setSelectedObj(item);
        setShow(true);
    };

    return (
        <Container title="Offers" viewBottomTab={true} isLoading={isLoading}>
            <Modal showOffer={show} dataFromOfferPage={selectedObj} setShowOffer={setShow} />

            <Row className="mt-5">
                {!!Ads?.length ? (
                    Ads?.map((item) => {
                        if (item?.type === "video") {
                            return (
                                <Col
                                    key={item?.id}
                                    xs={6}
                                    onClick={() => handleOnCardClick(item)}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div
                                        // href={item?.video_url}
                                        role={"button"}
                                        className="card w-100  text-center m-1 mb-2"
                                        style={{
                                            width: "fit-content",
                                            margin: "auto",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        <div className="card-body p-2">
                                            <img
                                                src={youtubeIcon}
                                                alt=""
                                                className="avatar avatar-80  coverimg text-center"
                                                style={{
                                                    borderRadius: "10px",
                                                    width: "100%",
                                                    height: "130px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <h3>{item?.title}</h3>
                                </Col>
                            );
                        }
                        return (
                            <Col
                                key={item?.id}
                                xs={6}
                                onClick={() => handleOnCardClick(item)}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <div
                                    role={"button"}
                                    className="card text-center m-1 mb-2"
                                    style={{
                                        width: "fit-content",
                                        margin: "auto",
                                        borderRadius: "10px",
                                    }}
                                >
                                    <div className="card-body p-2">
                                        <img
                                            src={item?.image}
                                            alt=""
                                            className="avatar avatar-80  coverimg text-center"
                                            style={{
                                                borderRadius: "10px",
                                                width: "100%",
                                                height: "130px",
                                            }}
                                        />
                                    </div>
                                </div>
                                <h3>{item?.title}</h3>
                            </Col>
                        );
                    })
                ) : (
                    <NoContent />
                )}
            </Row>
        </Container>
    );
}

export default Offers;
