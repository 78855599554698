import React, { useEffect } from "react";
import BottomTab from "../../../components/common/BottomTab";
import "./index.css";
import { useNavigate } from "react-router-dom";
function Games() {
    const Navigate = useNavigate();

    useEffect(() => {
        // scriptj.src = "";

        const script = document.createElement("script");
        script.src = "MENJA.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Cleanup
            document.body.removeChild(script);
        };
    }, []);
    return (
        <div className="menja pt-4">
            <div
                style={{
                    fontSize: "50px",
                    margin: "0px",
                    marginTop: "-30px",

                    position: "fixed",
                    zIndex: 555555,
                }}
                onClick={() => Navigate(-1)}
            >
                <i style={{ color: "white" }} className="bi bi-arrow-left-short"></i>
            </div>
            <canvas id="c"></canvas>

            <div className="hud">
                <div className="hud__score">
                    <div className="score-lbl"></div>
                    <div className="cube-count-lbl"></div>
                </div>
                <div className="pause-btn">
                    <div></div>
                </div>
                <div className="slowmo">
                    <div className="slowmo__bar"></div>
                </div>
            </div>

            <div className="menus">
                <div className="menu menu--main">
                    <h1>MENJA</h1>
                    <button type="button" className="play-normal-btn">
                        PLAY GAME
                    </button>
                    <button type="button" className="play-casual-btn">
                        CASUAL MODE
                    </button>
                    <div className="credits">
                        An 8kB game by <a href="https://cmiller.tech">Caleb Miller</a>
                    </div>
                </div>
                <div className="menu menu--pause">
                    <h1>Paused</h1>
                    <button type="button" className="resume-btn">
                        RESUME GAME
                    </button>
                    <button type="button" className="menu-btn--pause">
                        MAIN MENU
                    </button>
                </div>
                <div className="menu menu--score">
                    <h1>Game Over</h1>
                    <h2>Your Score:</h2>
                    <div className="final-score-lbl"></div>
                    <div className="high-score-lbl"></div>
                    <button type="button" className="play-again-btn">
                        PLAY AGAIN
                    </button>
                    <button type="button" className="menu-btn--score">
                        MAIN MENU
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Games;
