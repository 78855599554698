import React, { useEffect, useState } from "react";

import { useTheme } from "../../utils/Hooks";
import Text from "../common/Text";

import menu1 from "../../assets/img/setting-menu-1@2x.png";
import menu2 from "../../assets/img/setting-menu-2@2x.png";
import menu3 from "../../assets/img/setting-menu-3@2x.png";

function MenuStyleRow() {
  const { style, styleChange } = useTheme();
  const [MenuStyle, setMenuStyle] = useState(style["menu-mode"]);
  useEffect(() => {
    const menuStyle = style["menu-mode"];
    if (MenuStyle !== menuStyle) {
      styleChange("menu-mode", MenuStyle);
    }
  }, [MenuStyle, style, styleChange]);
  return (
    <div className="row ">
      <div className="col mb-4">
        <input
          type="radio"
          className="btn-check"
          name="menu-select"
          id="btn-menu1"
          value="overlay"
          checked={MenuStyle === "overlay"}
          onChange={() => setMenuStyle("overlay")}
        />
        <label
          className="btn btn-outline-primary background-btn p-1 text-center"
          htmlFor="btn-menu1"
        >
          <img src={menu1} alt="menu1" className="mw-100 border rounded-15" />
          <br />
          <span className="py-2 d-block small">
            <Text title="Popover" />
          </span>
        </label>
      </div>
      <div className="col mb-4">
        <input
          type="radio"
          className="btn-check"
          name="menu-select"
          id="btn-menu2"
          value="pushcontent"
          checked={MenuStyle === "pushcontent"}
          onChange={() => setMenuStyle("pushcontent")}
        />
        <label
          className="btn btn-outline-primary background-btn p-1 text-center"
          htmlFor="btn-menu2"
        >
          <img src={menu2} alt="menu2" className="mw-100 border rounded-15" />
          <br />
          <span className="py-2 d-block small">
            <Text title="PushPage" />{" "}
          </span>
        </label>
      </div>
      <div className="col mb-4">
        <input
          type="radio"
          className="btn-check"
          name="menu-select"
          id="btn-menu3"
          value="fullmenu"
          checked={MenuStyle === "fullmenu"}
          onChange={() => setMenuStyle("fullmenu")}
        />
        <label
          className="btn btn-outline-primary background-btn p-1 text-center"
          htmlFor="btn-menu3"
        >
          <img src={menu3} alt="menu3" className="mw-100 border rounded-15" />
          <br />
          <span className="py-2 d-block small">
            <Text title="Fullscreen" />
          </span>
        </label>
      </div>
    </div>
  );
}

export default MenuStyleRow;
