import { createContext, useState } from "react";

export const GuestContext = createContext({
    Guest: JSON.parse(localStorage.getItem("guest")) || false,
});

export function GuestProvider({ children }) {
    const [guest, setGuest] = useState(JSON.parse(localStorage.getItem("guest")) || false);

    const provider = {
        guest,
        useAddGuest: (Guest) => {
            localStorage.setItem("guest", JSON.stringify(Guest));
            return setGuest(Guest);
        },
        useClearGuest: (Guest = {}) => {
            localStorage.setItem("guest", JSON.stringify(Guest));
            return setGuest(Guest);
        },
    };
    return <GuestContext.Provider value={provider}>{children}</GuestContext.Provider>;
}
