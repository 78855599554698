import { useContext } from "react";

import { CartContext } from "./CartHook";
import { GuestContext } from "./GuestHook";
import { ThemeContext } from "./ThemeHook";
import { LanguageContext } from "../Resources/Language";

const useCart = () => useContext(CartContext);
const useTheme = () => useContext(ThemeContext);
const useLanguage = () => useContext(LanguageContext);
const useGuest = () => useContext(GuestContext);

export { useCart, useTheme, useLanguage, useGuest };
