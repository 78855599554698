function DynamicManifest() {
    const tenant = window.location.pathname.split("/")[1];
    const branch = window.location.pathname.split("/")[2];

    // const favicon = JSON.parse(localStorage.getItem("group"))?.favicon || "";

    // if (favicon) {
    //     const faviconLink = document.createElement("link");
    //     faviconLink.rel = "icon";
    //     faviconLink.href = favicon;

    //     const existingFavicon = document.querySelector("link[rel='icon']");
    //     if (existingFavicon) {
    //         existingFavicon.parentNode.replaceChild(faviconLink, existingFavicon);
    //     } else {
    //         document.head.appendChild(faviconLink);
    //     }
    // }

    var myDynamicManifest = {
        name: `QR Menu | ${tenant} | ${branch}`,
        short_name: `QR Menu | ${branch}`,
        start_url: window.location.href,
        display: "browser",
    };
    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);
    document.querySelector("#my-manifest-placeholder").setAttribute("href", manifestURL);

    return null;
}

export default DynamicManifest;
