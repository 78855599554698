import React, { useEffect, useState } from "react";
import { useLanguage, useTheme } from "../../utils/Hooks";
import Text from "../common/Text";

function DirectionRow() {
  const { style, styleChange } = useTheme();
  const [Direction, setDirection] = useState(style["direction-mode"]);
  const { userLanguageChange } = useLanguage();

  useEffect(() => {
    setDirection(style["direction-mode"]);
  }, [style]);

  const changeLanguage = (direction) => {
    styleChange("direction-mode", direction);
    userLanguageChange(direction === "ltr" ? "en" : "ar");
  };

  return (
    <div className="row mb-4">
      <div className="col-6 d-grid">
        <input
          type="radio"
          className="btn-check"
          name="layout-mode"
          id="btn-ltr"
          checked={Direction === "ltr"}
          onChange={() => changeLanguage("ltr")}
        />
        <label
          className="btn btn-outline-primary shadow-sm btn-rounded"
          htmlFor="btn-ltr"
        >
          <Text title="English" />
        </label>
      </div>
      <div className="col-6 d-grid">
        <input
          type="radio"
          className="btn-check"
          name="layout-mode"
          id="btn-rtl"
          checked={Direction === "rtl"}
          onChange={() => changeLanguage("rtl")}
        />
        <label
          className="btn btn-outline-primary shadow-sm btn-rounded"
          htmlFor="btn-rtl"
        >
          <Text title="العربية" />
        </label>
      </div>
    </div>
  );
}

export default DirectionRow;
