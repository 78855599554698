import { useLanguage } from "../../utils/Hooks";

export default function Text({ title }) {
    const languageContext = useLanguage();
    return languageContext.dictionary[title] || title;
}

export function Title({ en, ar, title }) {
    const languageContext = useLanguage();
    return languageContext.userLanguage === "en" ? en || title : ar || title;
}

export function Price({ price }) {
    const languageContext = useLanguage();
    return languageContext.userLanguage === "en" ? price : price;
}
