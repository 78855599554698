import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import Copyright from "./Copyright";
import Text from "./Text";

import Grip from "../../assets/img/Grip.svg";
import { useTheme } from "../../utils/Hooks";
import { useGetStyle } from "../../utils/API";
import { getQueryParams } from "../../utils/helpers";

function Sidebar({ setOpenMenu }) {
    const location = useLocation();
    const navigate = useNavigate();
    const { tenant, branch, table } = useParams();

    const { style, styleChange } = useTheme();
    const { data } = useGetStyle();

    const [MenuStyle, setMenuStyle] = useState(style["menu-mode"]);
    const [direction, setDirection] = useState(style["direction-mode"]);
    const [Theme, setTheme] = useState(style["theme-mode"]);

    useEffect(() => {
        setMenuStyle(style["menu-mode"]);
        setDirection(style["direction-mode"]);
        setTheme(style["theme-mode"]);
    }, [style]);

    const changeTheme = () => {
        const theme = Theme === "light-mode" ? "dark-mode" : "light-mode";
        styleChange("theme-mode", theme);
    };

    const handleNavigation = async (basePath) => {
        const queryString = await getQueryParams();
        navigate(basePath + (queryString ? `?${queryString}` : ""));
    };

    return (
        <div className={`sidebar-wrap sidebar-${MenuStyle}`}>
            <div className="closemenu text-secondary" role={"button"} onClick={() => setOpenMenu(false)}>
                <Text title={"CloseMenu"} />
            </div>
            <div className="sidebar d-flex flex-column justify-content-between">
                <div className="row my-5">
                    <div className="col-12">
                        <ul className="nav nav-pills">
                            {data?.nav_links
                                ?.filter((item) => (!table ? item.path !== "recipes" && item.path !== "plays" : item))
                                ?.map((item, index) => (
                                    <li key={index} className="nav-item">
                                        <Link
                                            className={
                                                "nav-link " +
                                                (location.pathname.split("/").length > (item?.path === null || item?.path === "cart") && table
                                                    ? 4
                                                    : 3 && location.pathname.split("/")[(item?.path === null || item?.path === "cart") && table ? 4 : 3] === item.name_en
                                                    ? "active"
                                                    : "")
                                            }
                                            onClick={() =>
                                                handleNavigation(
                                                    item.path == "/expired"
                                                        ? `expired`
                                                        : item?.path === "cart" && table
                                                        ? `/${tenant}/${branch}/${table}/${item.path}`
                                                        : item.path
                                                        ? table
                                                            ? `/${tenant}/${branch}/${table}/${item.path}`
                                                            : `/${tenant}/${branch}/${item.path}`
                                                        : table
                                                        ? `/${tenant}/${branch}/${table}`
                                                        : `/${tenant}/${branch}/`
                                                )
                                            }
                                            to={"#"}
                                            tabIndex="-1"
                                        >
                                            <div className="avatar avatar-40 icon">
                                                {" "}
                                                <i className={`${item?.icon}`}></i>
                                            </div>
                                            <div className="col">
                                                <Text title={item.name_en} />
                                                <Text title={" "} />
                                            </div>
                                            <div className="arrow d-flex align-items-center">
                                                <i className={direction === "ltr" ? "bi bi-chevron-right" : "bi bi-chevron-left"}></i>
                                            </div>
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>

                <div className="d-flex flex-column">
                    {table ? (
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <span className="mt-1">
                                <Text title={"ThemeMode"} />
                            </span>
                            <div className="btn w-auto h-auto p-0">
                                <span className="form-check form-switch position-relative">
                                    <span className="bi bi-moon position-absolute" onClick={changeTheme} style={{ top: "8px", right: "5px" }}></span>
                                    <input type="checkbox" className="form-check-input custom-checkbox" id="one" style={{ width: "50px", height: "25px" }} checked={Theme === "dark-mode"} onChange={changeTheme} />
                                </span>
                            </div>
                        </div>
                    ) : null}
                    <img className="align-self-center rounded" src={Grip} alt="Grip" height={50} />
                    <Copyright />
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
