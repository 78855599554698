import React, { useEffect, useRef, useState } from "react";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";

import { useGetTermsConditions } from "../utils/API";

import Container from "../components/common/Container";
import NoContent from "../components/common/NoContent";
import { useLanguage } from "../utils/Hooks";

function TermsConditions() {
  const template = useRef(null);
  const { userLanguage } = useLanguage();
  const { isLoading, data } = useGetTermsConditions();

  const [HTML, setHTML] = useState(null);

  useEffect(() => {
    if (data?.results?.body) {
      const raw = JSON.parse(
        userLanguage === "en" ? data?.results?.body_en : data?.results?.body_ar
      );
      const state = convertFromRaw(raw);
      setHTML(stateToHTML(state));
    }
  }, [data, userLanguage]);

  const createElementFromHTML = (htmlString) => {
    if (template?.current) template.current.innerHTML = htmlString.trim();
  };

  if (data?.results === null)
    return (
      <Container title="Terms & Conditions">
        <NoContent />
      </Container>
    );
  else if (data?.results?.body) {
    return (
      <Container isLoading={isLoading} title="Terms & Conditions">
        <div className="my-4" ref={template}>
          {createElementFromHTML(HTML)}
        </div>
      </Container>
    );
  }
}

export default TermsConditions;
