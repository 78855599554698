import { createContext, useEffect, useState } from "react";
import { dictionaryList } from "./languages";
// create the language context with default selected language
export const LanguageContext = createContext({
    userLanguage: "ar",
    dictionary: dictionaryList.ar,
});
// it provides the language context to app
export function LanguageProvider({ children }) {
    const check_direction_mode = localStorage.getItem("direction-mode");

    const [userLanguage, setUserLanguage] = useState(check_direction_mode === "ltr" ? "en" : "ar");
    useEffect(() => {
        setUserLanguage(check_direction_mode === "ltr" ? "en" : "ar");
    }, [check_direction_mode]);

    const provider = {
        userLanguage,
        dictionary: dictionaryList[userLanguage],
        userLanguageChange: (selected) => {
            setUserLanguage(selected);
            localStorage.setItem("lang", selected);
        },
    };
    return <LanguageContext.Provider value={provider}>{children}</LanguageContext.Provider>;
}
