import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Text from "../common/Text";
import { useGuest } from "../../utils/Hooks";

function GuestInfoModal({ isLoading, openGuestModal }) {
    const [GuestModal, setGuestModal] = useState(false);
    const [GuestForm, setGuestForm] = useState({
        name: "",
        mobile: "",
    });

    const { guest, useAddGuest } = useGuest();

    
    useEffect(() => {
        if (!guest && !isLoading && openGuestModal) {
            setGuestModal(true);
        }
    }, [openGuestModal]);

    const handleGuest = (is_anonymous) => {
        const item = {
            ...GuestForm,
            is_anonymous,
        };
        useAddGuest(item);
        setGuestModal(false);
        setGuestForm({
            name: "",
            mobile: "",
        });
    };

    return (
        <Modal show={GuestModal} centered onHide={() => setGuestModal(false)} animation={false}>
            <Modal.Header className="bg-light" closeButton>
                <Modal.Title>
                    <Text title="GuestInfo" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-light">
                <Form.Label htmlFor="name">
                    <Text title="Name" />
                </Form.Label>
                <Form.Control id="name" type="text" className="mb-2" value={GuestForm.name} onChange={(e) => setGuestForm((item) => ({ ...item, name: e.target.value }))} />

                <Form.Label htmlFor="mobile">
                    <Text title="Mobile" />
                </Form.Label>
                <Form.Control id="mobile" type="text" className="mb-2" value={GuestForm.mobile} onChange={(e) => setGuestForm((item) => ({ ...item, mobile: e.target.value }))} />
            </Modal.Body>
            <Modal.Footer className="bg-light">
                <Button variant="secondary" onClick={() => handleGuest(true)}>
                    <Text title="AsGuest" />
                </Button>
                <Button variant="primary" onClick={() => handleGuest(false)}>
                    <Text title="SaveChanges" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default GuestInfoModal;
