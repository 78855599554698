import React from "react";
import Text from "./Text";

function NoContent() {
    return (
        <div className="row justify-content-center mt-5">
            <div className="col-12 col-md-6 col-md-12">
                <div className="row h-100 justify-content-center">
                    <div className="col-10 col-md-6 col-lg-12 align-self-end text-center mb-4">
                        <i className="bi bi-emoji-frown color-primary" style={{ fontSize: 100 }} />
                        <p className="text-secondary pt-3">
                            <Text title="NoContent" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NoContent;
