import { createBrowserRouter } from "react-router-dom";
import ExpirePage from "../pages/ExpirePage";

const expire = createBrowserRouter([
    {
        path: "*",
        element: <ExpirePage />,
    },
    {
        path: "/expired",
        element: <ExpirePage />,
    },
]);

export default expire;
