import { useParams } from "react-router-dom";
import CatgoriesComponent from "../components/Recipe/Catgories";
import Container from "../components/common/Container";

function CatgoriesPage() {
  return (
    <Container title="Cart" viewBottomTab={true}>
      <CatgoriesComponent />
    </Container>
  );
}

export default CatgoriesPage;
