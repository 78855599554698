import React from "react";
import Text from "../common/Text";

function SearchBar({ setOpenFilter, SearchQuery, setSearchQuery }) {
  return (
    <div className="row mb-3">
      <div className="col-12 overflow-hidden">
        <div className="row">
          <div className="col position-relative align-self-center">
            <div className="form-group form-floating mb-3 is-valid">
              <input
                type="text"
                className="form-control"
                value={SearchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
              />
              <label className="form-control-label" htmlFor="searchmain">
                <Text title={"Search"} />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchBar;
