import React from "react";
import Container from "../components/common/Container";
import Text from "../components/common/Text";

function AboutUs() {
    return (
        <Container title="About Us">
            <div className="row">
                <div className="col-12 mx-2 pt-4">
                    <h1 className="display-4 mb-0">
                        <Text title={`QR Menu`} />
                    </h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mx-2 py-2">
                    <h4>
                        <Text title={`Welcome, where we're redefining the dining experience through innovation and simplicity.`} />
                    </h4>
                    <p className="text-secondary mt-3">
                        <Text title={`Our passion is to enhance the way you explore menus and order your favorite dishes, all with a quick scan.`} />
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mx-2 py-3">
                    <h4>
                        <Text title={`Our Mission`} />
                    </h4>
                    <p className="text-secondary mt-3">
                        <Text title={`Our mission is`} />
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-6 col-md-6 px-2">
                    <div className="card mb-3 shadow-sm">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h6 className="mb-2">
                                        <Text title={`Innovation`} />
                                    </h6>
                                    <p className="text-secondary small">
                                        <Text title={`We're not just a QR menu app; we're a solution that embraces innovation. We stay ahead of the curve to provide you with the latest features and enhancements.`} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-6 px-2">
                    <div className="card mb-3 shadow-sm">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h6 className="mb-2">
                                        <Text title={`Empowering Restaurants`} />
                                    </h6>
                                    <p className="text-secondary small">
                                        <Text title={`It isn't just for customers; it's a tool for restaurant owners too. We provide robust features for establishments to manage menus, track orders, and streamline their operations.`} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6 col-md-6 px-2">
                    <div className="card mb-3 shadow-sm">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h6 className="mb-2">
                                        <Text title={`Simplicity`} />
                                    </h6>
                                    <p className="text-secondary small">
                                        <Text title={`We believe in the beauty of simplicity. Our app is designed to be user-friendly, ensuring that anyone can navigate menus with ease.`} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-6 px-2">
                    <div className="card mb-3 shadow-sm">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h6 className="mb-2">
                                        <Text title={`Efficiency`} />
                                    </h6>
                                    <p className="text-secondary small">
                                        <Text title={`Say goodbye to lengthy waiting times. With a simple scan, you have the menu at your fingertips, and ordering is just a few taps away.`} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6 col-md-6 px-2">
                    <div className="card mb-3 shadow-sm">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h6 className="mb-2">
                                        <Text title={`Variety`} />
                                    </h6>
                                    <p className="text-secondary small">
                                        <Text title={`Explore diverse cuisines and discover hidden gems on menus. We connect you with a world of culinary delights.`} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-6 px-2 ">
                    <div className="card mb-3 shadow-sm">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h6 className="mb-2">
                                        <Text title={`Safety`} />
                                    </h6>
                                    <p className="text-secondary small">
                                        <Text title={`In a world that values health and safety, our app promotes contactless menu browsing and ordering, reducing the need for physical menus.`} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default AboutUs;
