import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Text from "../components/common/Text";
import I404 from "../assets/img/404.png";
import error404 from "../assets/img/error404.jpg";
import Container from "../components/common/Container";

function P404() {
    const navigate = useNavigate();

    return (
        <Container title="Not found 404">
            <div className="row h-75">
                <div className="col-12 h-flex py-3 position-relative" style={{ height: "522px" }}>
                    <img alt="" src={error404} className="coverimg h-50 w-100 position-absolute top-0 start-0" />
                    <div className="row h-75 justify-content-center">
                        <div className="col-12 align-self-end text-center mb-4">
                            <img src={I404} className="mx-auto mw-100" alt={"404"} />
                            <p className="text-secondary">
                                <Text title="P404" />
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <Link onClick={() => navigate(-1)} className="btn btn-default w-100 btn-lg shadow-sm btn-rounded">
                        <Text title="BackHome" />
                    </Link>
                </div>
            </div>
        </Container>
    );
}

export default P404;
