import React from "react";

function Spinner() {
    return (
        <div className="col-10 col-md-6 col-lg-5 col-xl-3 mx-auto text-center align-self-center">
            <div className="mt-5 circular-loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

export default Spinner;
