import React, { useEffect, useState, useRef } from "react";
import { Card } from "react-bootstrap";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, useMapEvents, Polygon } from "react-leaflet";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import Text from "./Text";
import { toast } from "react-toastify";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: new L.Point(12, 35),
});

L.Marker.prototype.options.icon = DefaultIcon;

function MapModal({ setCoordinates, setAddress, coordinates, isWithinRadius, area }) {
    const [temp, setTemp] = useState();
    const [tempCord, setTempCord] = useState(coordinates);
    const [locationFetched, setLocationFetched] = useState(false);
    const mapRef = useRef(null);

    useEffect(() => {
        if (!locationFetched && area) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    () => {
                        setTempCord({
                            lat: area?.center_latitude,
                            lng: area?.center_longitude,
                        });
                        const mapInstance = mapRef.current;
                        if (mapInstance) {
                            mapInstance.flyTo(
                                {
                                    lat: area?.center_latitude,
                                    lng: area?.center_longitude,
                                },
                                mapInstance.getZoom()
                            );
                        }
                        setLocationFetched(true);
                    },
                    (error) => {
                        console.error("Error getting location:", error);
                    }
                );
            } else {
                console.error("Geolocation is not supported by your browser");
            }
        }
    }, [area]);

    const moveToCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const currentLocation = { lat: latitude, lng: longitude };
                    const check = isWithinRadius({ lat: latitude, lng: longitude }, area?.custom_shape_coordinates);
                    if (check) {
                        setTempCord(currentLocation);
                        reverseGeocode(currentLocation);
                    } else {
                        setAddress("");
                        toast.error(<Text title="Selected location out of delivery area" />);
                    }
                    const mapInstance = mapRef.current;
                    if (mapInstance) {
                        mapInstance.flyTo(currentLocation, mapInstance.getZoom());
                    }
                },
                (error) => {
                    console.error("Error getting current location:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by your browser");
        }
    };

    const handleMapClick = (e) => {
        const clickedCoordinates = {
            lat: e.latlng.lat,
            lng: e.latlng.lng,
        };
        const check = isWithinRadius(clickedCoordinates, area?.custom_shape_coordinates);
        if (check) {
            setTempCord(clickedCoordinates);
            reverseGeocode(clickedCoordinates);
        } else {
            setAddress("");
            toast.error(<Text title="Selected location out of delivery area" />);
        }
    };

    const reverseGeocode = async (location) => {
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${location.lat}&lon=${location.lng}&format=json`);
            const data = await response.json();
            setTemp(data.display_name || "Location not found");
        } catch (error) {
            console.error("Error reverse geocoding:", error);
        }
    };

    return (
        <Card className="mt-3">
            <Card.Body className="p-1">
                <div style={{ height: "300px", marginTop: "10px" }}>
                    <MapContainer center={tempCord} zoom={13} scrollWheelZoom={false} style={{ height: "100%", width: "100%", zIndex: 0 }} ref={mapRef}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="" />
                        <LocationMarker position={tempCord} map={mapRef.current} handleMapClick={handleMapClick} />
                        <Polygon positions={area?.custom_shape_coordinates || []} pathOptions={{ color: "green" }} />
                    </MapContainer>
                </div>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-end">
                <button className="btn btn-outline-secondary m-0 px-2 mx-2 py-1" type="button" onClick={moveToCurrentLocation}>
                    <i className="bi bi-geo mx-1" style={{ fontSize: 13 }} /> <Text title="Current Location" />
                </button>
                <button
                    className="btn btn-primary m-0 px-3 py-1"
                    type="button"
                    onClick={() => {
                        setAddress(temp);
                        setCoordinates(tempCord);
                        toast.success(<Text title="Selected location saved!" />);
                    }}
                >
                    <Text title="Confirm" />
                </button>
            </Card.Footer>
        </Card>
    );
}

export default MapModal;

function LocationMarker({ position, handleMapClick }) {
    useMapEvents({
        click(e) {
            handleMapClick(e);
        },
    });
    return position === null ? null : <Marker position={position}></Marker>;
}
