import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

const getBaseUrl = () => {
    const [, tenant, branch] = window.location.pathname.split("/");
    return { baseUrl: `https://${tenant}.${process.env.REACT_APP_BASEURL}`, tenant, branch };
};

const params = {
    retry: 0,
};

const getLanguage = () => {
    return localStorage.getItem("lang") || "en";
};

const Fetch = (url, params) => {
    return axios.get(url, {
        params: {
            domain: window.location.origin,
            ...params,
        },
        headers: {
            ["Accept-Language"]: getLanguage(),
        },
    });
};

export const useGetMenu = () => {
    const name = "repoData";
    const { baseUrl, branch } = getBaseUrl();
    const url = `${baseUrl}/menus/frontend/?branch_slug=${branch}`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url), params);
    return { isLoading, error, data: data?.data };
};

export const useGetStyle = () => {
    const name = "styleData";
    const { baseUrl, branch } = getBaseUrl();
    const url = `${baseUrl}/branches/styles/?branch_slug=${branch}`;
    const { isLoading, error, data } = useQuery(name, () => (branch ? Fetch(url) : null), params);
    return { isLoading, error, data: data?.data };
};

export const useGetTermsConditions = () => {
    const name = "terms-conditions";
    const { baseUrl, branch } = getBaseUrl();
    const url = `${baseUrl}/branches/terms-conditions/?branch_slug=${branch}`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url), params);
    return { isLoading, error, data: data?.data };
};

export const useGetContactPage = () => {
    const name = "contact-page";
    const { baseUrl, branch } = getBaseUrl();
    const url = `${baseUrl}/branches/contact-page/?branch_slug=${branch}`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url), params);
    return { isLoading, error, data: data?.data };
};
export const useGetRecipesCategory = () => {
    const name = "recipies-categories";
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/recipies/categories/`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url), params);
    return { isLoading, error, data: data?.data };
};
export const useGetRecipesCategoriesById = (id) => {
    const name = "recipies";
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/recipies/?category=${id}`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url), params);
    return { isLoading, error, data: data?.data };
};
export const useGetRecipesById = (id) => {
    const name = "onerecipies";
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/recipies/${id}`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url), params);
    return { isLoading, error, data: data?.data };
};
export const useGetAds = () => {
    const name = "ads";
    const { baseUrl, branch } = getBaseUrl();
    const url = `${baseUrl}/menus/frontend/popup-ads/?branch_slug=${branch}`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url), params);
    return { isLoading, error, data: data?.data };
};
export const useGetProductHook = (id) => {
    const name = "Product";
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/menus/products/${id}`;
    const { isLoading, error, data } = useQuery([name, id], () => Fetch(url), params);
    return { isLoading, error, data: data?.data };
};

const PostReview = async (data) => {
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/menus/frontend/add-review/`;
    return axios.post(url, data);
};

export const usePostReview = () => {
    return useMutation(PostReview, {
        onSuccess: () => {
            toast.success("Review Added Successfully");
        },
        onError: ({ response }) => {
            toast.error(response?.data?.detail);
        },
    });
};

const GetCheckAvailability = async (data) => {
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/reservations/check-dates/?guests_no=${data?.guests_no}&reserved_date=${data?.reserved_date}&reserved_time=${data?.reserved_time}&tenant_slug=${data?.tenant_slug}&branch_slug=${data?.branch_slug}`;
    return axios.get(url);
};

export const useGetCheckAvailability = () => {
    return useMutation(GetCheckAvailability, {
        onSuccess: (res) => {
            return res.data;
        },
        onError: (err) => {
            console.error(err);
        },
    });
};

const PostReservation = async (data) => {
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/reservations/make-reservation/`;
    return axios.post(url, data);
};

export const usePostReservation = () => {
    return useMutation(PostReservation, {
        onSuccess: (res) => {
            toast.success(res?.data?.responseMessage);
        },
        onError: ({ response }) => {
            toast.error(response?.data?.detail);
        },
    });
};

const PostOnlineOrder = async (data) => {
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/tables/orders/create-online-order/`;
    return axios.post(url, data);
};

export const usePostOnlineOrder = () => {
    return useMutation(PostOnlineOrder, {
        onSuccess: (res) => {
            res?.data?.responseMessage?.map((message) => toast.success(message));
        },
        onError: ({ response }) => {
            response?.data?.responseMessage?.map((message) => toast.error(message));
        },
    });
};

export const useGetOrder = (params) => {
    const name = "order-details";
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/tables/orders/order-details/${params?.id}?tenant_slug=${params?.tenant}`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url));
    return { isLoading, error, data: data?.data };
};

export const useGetAreas = (params) => {
    const name = "branches/areas";
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/branches/areas/front/?tenant_slug=${params?.tenant}&branch_slug=${params?.branch}`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url));
    return { isLoading, error, data: data?.data };
};

const PostCheckDistance = async (data) => {
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/tables/orders/check-distance/`;
    return axios.post(url, data, {
        headers: {
            "Accept-Language": getLanguage(),
        },
    });
};

export const useCheckDistance = () => {
    return useMutation(PostCheckDistance);
};

const PostCheckout = async (data) => {
    const { baseUrl } = getBaseUrl();
    const url = `${baseUrl}/tables/orders/checkout/`;
    return axios.post(url, data, {
        headers: {
            "Accept-Language": getLanguage(),
        },
    });
};

export const useCheckout = () => {
    return useMutation(PostCheckout);
};

export const useGetBranches = () => {
    const name = "branches";
    const { baseUrl, tenant } = getBaseUrl();
    const url = `${baseUrl}/tenants/branches/`;
    const { isLoading, error, data } = useQuery(name, () => Fetch(url, { busisnessName: tenant }));
    return { isLoading, error, data: data?.data };
};
