import React, { useEffect, useState } from "react";
import MenuSwiper from "./MenuSwiper";
import SubMenuSwiper from "./SubMenuSwiper";
import Recipes from "./Recipes";
// import OffersGrid from "./OffersGrid";
// import PopularList from "./PopularList";

import Container from "../common/Container";
import { useGetMenu } from "../../utils/API";

import Error from "../../pages/Error";
function Index() {
  const { isLoading, error, data } = useGetMenu();
  const [selectedCategory, setSelectedCategory] = useState(
    parseInt(localStorage.getItem("selectedCategory") || 0)
  );
  const [selectedSubCategory, setSubSelectedCategory] = useState(
    parseInt(localStorage.getItem("selectedSubCategory") || 0)
  );

  const [SearchQuery, setSearchQuery] = useState("");

  const [categoryProducts, setCategoryProducts] = useState([]);

  const [SearchProducts, setSearchProducts] = useState([]);
  return (
    <>
      {/* {SearchQuery === "" && (
        <MenuSwiper
          categories={data?.categories}
          setSelectedCategory={setSelectedCategory}
          setSubSelectedCategory={setSubSelectedCategory}
          selectedCategory={selectedCategory}
        />
      )} */}

      {/* <SmPromotion /> */}
      {data?.categories?.length > 0 &&
        data?.categories[selectedCategory]?.subcategories.length > 0 && (
          <Recipes
            products={
              SearchQuery === ""
                ? categoryProducts.length > 0
                  ? categoryProducts
                  : data?.categories[selectedCategory]?.subcategories[
                      selectedSubCategory
                    ]?.products
                : SearchProducts
            }
          />
        )}
    </>
  );
}

export default Index;
