import React from "react";
import { useParams } from "react-router-dom";
import { useGetOrder } from "../utils/API";
import Container from "../components/common/Container";
import Text, { Price } from "../components/common/Text";
import moment from "moment";
import { useLanguage } from "../utils/Hooks";

function TrackOrder() {
    const { tenant, id } = useParams();
    const { userLanguage } = useLanguage();
    const { data, isLoading } = useGetOrder({ tenant, id });

    return (
        <Container isLoading={isLoading} title={"Order Tracking"}>
            <div className="card mt-4">
                <div className="row my-2">
                    <div className="col d-flex justify-content-center align-items-center">{data?.is_canceled === true ? <i className="bi-x-circle text-danger" style={{ fontSize: 58 }} /> : <i className="bi-check2-circle text-success" style={{ fontSize: 58 }} />}</div>
                </div>
                <div className="row">
                    <div className="col d-flex justify-content-center align-items-center">
                        <strong className="fs-5 mb-0">#{data?.order_no}</strong>
                    </div>
                </div>
                <div className="row my-1">
                    <div className="col-12 d-flex justify-content-center align-items-center px-4">
                        <p className="fs-5 mb-0 text-muted">{moment(data?.create_at).locale(userLanguage).format("DD-MM-YYYY / hh:mm a")}</p>
                    </div>
                </div>
                <div className="row my-1">
                    <div className="col-12 d-flex justify-content-center align-items-center px-4">
                        <p className="fs-5 mb-0 text-muted">
                            {data?.is_canceled === true ? (
                                <span className="text-danger">
                                    <Text title={`Sorry, the order has been canceled`} />
                                </span>
                            ) : (
                                <span>
                                    <Text title={`Thank you for ordering`} /> {data?.customer?.name}
                                </span>
                            )}
                        </p>
                    </div>
                </div>
            </div>
            <div className="row my-3 position-relative">
                {data?.items?.map((product, index) => (
                    <div key={index} className="col-12" role={"button"}>
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-auto pe-0">
                                        <img alt="" src={product?.image} className="avatar avatar-80 rounded-15 coverimg text-center mt-2" />
                                    </div>
                                    <div className="col align-self-center">
                                        <p className="mb-1">
                                            {product?.qty} X <Text title={product?.name} />
                                        </p>
                                        <p className="mb-1 text-muted">
                                            <Price price={product?.total_value} />
                                        </p>
                                        {product?.modifiers?.map((modifier, modIndex) => (
                                            <div key={modIndex} className="d-flex flex-row justify-content-between">
                                                <p className="size-10 mb-1">
                                                    {modifier?.qty} X <Text title={modifier?.name} />
                                                </p>
                                                <p className="size-10 mb-1">
                                                    <Price price={modifier?.total_value} />
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="card" style={{ marginTop: -12 }}>
                <div className="card-body">
                    <div className="row my-2">
                        <div className="col">
                            <p>
                                <Text title={"Order Type"} />
                            </p>
                        </div>
                        <div className="col-auto fw-bold">
                            <Text title={data?.order_type} />
                        </div>
                    </div>
                    <div className="row my-2">
                        <div className="col">
                            <p>
                                <Text title={"Payment Type"} />
                            </p>
                        </div>
                        <div className="col-auto fw-bold">{data?.payment_method}</div>
                    </div>
                    <div className="row my-2">
                        <div className="col">
                            <p>
                                <Text title={"Payment Status"} />
                            </p>
                        </div>
                        <div className={`col-auto fw-bold ${data?.is_paid ? "text-success" : "text-danger"}`}>{data?.is_paid ? <Text title={"Paid"} /> : <Text title={"Unpaid"} />}</div>
                    </div>
                    <div className="row my-2">
                        <div className="col">
                            <p>
                                <Text title={data?.order_type === "Delivery" ? "delivery_time" : "pickup_time"} />
                            </p>
                        </div>
                        <div className="col-auto fw-bold">
                            <Text title={data?.pickup_time ? moment(data?.pickup_time).locale(userLanguage).format("DD-MM-YYYY / hh:mm a") : "As soon as possible"} />
                        </div>
                    </div>
                    {data?.delivery_fees_formatted && (
                        <div className="row my-2">
                            <div className="col">
                                <p>
                                    <Text title={"Delivery Fees"} />
                                </p>
                            </div>
                            <div className="col-auto fw-bold">{data?.delivery_fees_formatted}</div>
                        </div>
                    )}
                    <div className="row my-2">
                        <div className="col">
                            <p>
                                <Text title={"Subtotal"} />
                            </p>
                        </div>
                        <div className="col-auto fw-bold">{data?.subtotal_formatted}</div>
                    </div>
                    <div className="row my-2">
                        <div className="col">
                            <p>
                                <Text title={"Total"} />
                            </p>
                        </div>
                        <div className="col-auto fw-bold">{data?.total_formatted}</div>
                    </div>
                </div>
            </div>
            <div className="card my-4">
                <div className="card-header">
                    <h6>
                        <Text title={"Customer Info."} />
                    </h6>
                </div>
                <div className="card-body">
                    <div className="row my-1">
                        <div className="col">
                            <p className="size-14">
                                <Text title={"Name"} />
                            </p>
                        </div>
                        <div className="col-auto fw-bold size-14">
                            <Text title={data?.customer?.name} />
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col">
                            <p className="size-14">
                                <Text title={"Mobile"} />
                            </p>
                        </div>
                        <div className="col-auto fw-bold size-14">
                            <Text title={data?.customer?.mobile} />
                        </div>
                    </div>
                    {data?.customer?.email && (
                        <div className="row my-1">
                            <div className="col">
                                <p className="size-14">
                                    <Text title={"Email"} />
                                </p>
                            </div>
                            <div className="col-auto fw-bold size-14">
                                <Text title={data?.customer?.email} />
                            </div>
                        </div>
                    )}
                    {data?.address && (
                        <>
                            <div className="row my-1">
                                <div className="col">
                                    <p className="size-14">
                                        <Text title={"Area"} />
                                    </p>
                                </div>
                                <div className="col-auto fw-bold size-14">
                                    <Text title={data?.address?.area_name} />
                                </div>
                            </div>
                            {data?.address?.address && (
                                <div className="row my-1">
                                    <div className="col">
                                        <p className="size-14">
                                            <Text title={"Address"} />
                                        </p>
                                    </div>
                                    <div className="col-auto fw-bold size-14">
                                        <Text title={data?.address?.address} />
                                    </div>
                                </div>
                            )}
                            {data?.address?.address_details && (
                                <div className="row my-1">
                                    <div className="col">
                                        <p className="size-14">
                                            <Text title={"address_details"} />
                                        </p>
                                    </div>
                                    <div className="col-auto fw-bold size-14">
                                        <Text title={data?.address?.address_details} />
                                    </div>
                                </div>
                            )}
                            {data?.address?.note && (
                                <div className="row my-1">
                                    <div className="col">
                                        <p className="size-14">
                                            <Text title={"notes"} />
                                        </p>
                                    </div>
                                    <div className="col-auto fw-bold size-14">
                                        <Text title={data?.address?.note} />
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            <div className="clearfix"></div>
        </Container>
    );
}

export default TrackOrder;
