import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAds } from "../../utils/API";
import { useTheme } from "../../utils/Hooks";
import { getQueryParams } from "../../utils/helpers";
import ReactPlayer from "react-player";

function AdsModal({ showOffer, setShowOffer, dataFromOfferPage = null }) {
    const { setAds } = useTheme();
    const { data } = useGetAds();
    const [show, setShow] = useState(false);
    useEffect(() => {
        if (!show) {
            setTimeout(() => {
                setShow(true);
            }, 2000);
        }
    }, []);
    useEffect(() => {
        if (data?.results) setAds(data?.results);
    }, [setAds, data?.results]);

    const handleClose = () => {
        setShow(false);
        if (setShowOffer) setShowOffer(false);
    };

    const [ShowCloseIcon, setShowCloseIcon] = useState(false);

    const [randomData, setRandomData] = useState(null);
    const getRandomObject = (array) => {
        const filtered = array.filter((item) => item?.show_in !== "2");
        const random = Math.floor(Math.random() * filtered?.length);
        const randomObject = filtered[random];
        setRandomData(randomObject);
    };

    useEffect(() => {
        if (dataFromOfferPage && typeof dataFromOfferPage == "object") {
            setRandomData(dataFromOfferPage);
        } else {
            if (data?.results?.length > 0) {
                getRandomObject(data?.results);
            }
        }
    }, [data, dataFromOfferPage]);
    useEffect(() => {
        if (randomData) {
            setTimeout(() => {
                setShowCloseIcon(true);
            }, 2000);
        }
    }, [randomData]);

    const { tenant, branch, table } = useParams();

    const navigate = useNavigate();

    const toProduct = async (id) => {
        const queryString = await getQueryParams();
        const basePath = table ? `/${tenant}/${branch}/${table}/product/${id}` : `/${tenant}/${branch}/product/${id}`;
        navigate(basePath + (queryString ? `?${queryString}` : ""));
    };

    return (
        <div>
            {randomData && (
                <Modal className={`d-flex justify-content-center align-items-center ${randomData?.type == "video" ? "modal-video" : null}`} show={show || showOffer} onHide={handleClose}>
                    <Modal.Body className="p-0">
                        {ShowCloseIcon && (
                            <button
                                type="button"
                                className="btn-close font-large"
                                aria-label="Close"
                                style={{
                                    position: "absolute",
                                    right: "0px",
                                    fontWeight: "bold",
                                    fontSize: "25px",
                                }}
                                onClick={handleClose}
                            ></button>
                        )}

                        {randomData?.type == "video" && randomData?.video_url != "null" ? (
                            <div>
                                <ReactPlayer url={randomData?.video_url} controls muted playing width={"100%"} height={"100%"} />
                            </div>
                        ) : (
                            <>
                                <img
                                    style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                    }}
                                    src={randomData?.image}
                                    onClick={() => toProduct(randomData?.linked_product?.id)}
                                />
                            </>
                        )}
                    </Modal.Body>
                </Modal>
            )}
        </div>
    );
}

export default AdsModal;
