import React, { useEffect } from "react";
import BottomTab from "../components/common/BottomTab";
import { useNavigate } from "react-router-dom";

function Games() {
    const Navigate = useNavigate();

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "flappy.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Cleanup
            document.body.removeChild(script);
        };
    }, []);
    return (
        <>
            <div className="  games flappy-game container theme-light-radial-gradient">
                <div
                    style={{
                        fontSize: "50px",
                        margin: "0px",
                        position: "fixed",
                        zIndex: 555555,
                        left: "0px",
                        top: "0px",
                    }}
                    onClick={() => Navigate(-1)}
                >
                    <i style={{ color: "white" }} className="bi bi-arrow-left-short"></i>
                </div>
                <canvas style={{ width: "100%", height: "100%" }} id="gridone"></canvas>

                <footer>
                    <div className="d-none footer_container">
                        <div className="  mode_container">
                            <span>Light</span>
                            <div className="btn_container">
                                <button className="btn">
                                    <div className="circle"></div>
                                </button>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default Games;
