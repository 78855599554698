import React from "react";

import ThemeRow from "../components/Styles/ThemeRow";
import ColorRow from "../components/Styles/ColorRow";
import DirectionRow from "../components/Styles/DirectionRow";
import BgImageRow from "../components/Styles/BgImageRow";
import MenuStyleRow from "../components/Styles/MenuStyleRow";
import Container from "../components/common/Container";
import Text from "../components/common/Text";

function Styles() {
  return (
    <Container title="Styles" viewBottomTab={true}>
      <p className="text-opac text-center mb-4">
        <Text title="StylesHeader" />
      </p>
      <div className="row mb-3">
        <div className="col-12">
          <h6 className="mb-0">
            <Text title="LayoutMode" />
          </h6>
        </div>
      </div>
      <ThemeRow />
      <div className="row mb-3">
        <div className="col-12">
          <h6 className="mb-0">
            <Text title="ColorScheme" />
          </h6>
        </div>
      </div>
      <ColorRow />
      <div className="row mb-3">
        <div className="col-12">
          <h6 className="mb-0">
            <Text title="Language" />
          </h6>
        </div>
      </div>
      <DirectionRow />

      <div className="row mb-3">
        <div className="col-12">
          <h6 className="mb-1">
            <Text title="BackgroundImage" />
          </h6>
          <p className="text-secondary">
            <Text title="BackgroundDesc" />
          </p>
        </div>
      </div>
      <BgImageRow />

      <div className="row mb-3">
        <div className="col-12">
          <h6 className="my-1">
            <Text title="MenuStyle" />
          </h6>
          <p className="text-secondary"></p>
        </div>
      </div>
      <MenuStyleRow />
    </Container>
  );
}

export default Styles;
