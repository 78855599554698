import React, { useEffect } from "react";

import Container from "../components/common/Container";
import Text from "../components/common/Text";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetBranches } from "../utils/API";

function Landing() {
    const { tenant } = useParams();
    const navigate = useNavigate();

    const { data, isLoading } = useGetBranches();

    useEffect(() => {
        if (data?.responseData?.length === 1) {
            const branch = data?.responseData?.[0];
            navigate(`/${tenant}/${branch?.slug_name}/`);
        }
    }, [data?.responseData]);

    return (
        <Container title="Select Branch" landing={true} isLoading={isLoading}>
            <div className="mt-4 d-flex justify-content-center">
                <h4 className="text-center">
                    <Text title="Please, select the nearest branch to your location" />
                </h4>
            </div>
            <div className="my-5">
                {data?.responseData?.map((branch) => (
                    <div className="my-4" key={branch?.id}>
                        <Link to={`/${tenant}/${branch?.slug_name}/`} className="btn btn-default w-100 btn-rounded py-3">
                            <h5>
                                <Text title={branch?.title} />
                            </h5>
                        </Link>
                    </div>
                ))}
            </div>
        </Container>
    );
}

export default Landing;
