import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Grip from "../../assets/img/grip.png";
import { getQueryParams } from "../../utils/helpers";
import { useTheme } from "../../utils/Hooks";

function Logo({ cover }) {
    const { style, AddOns } = useTheme();
    const navigate = useNavigate();

    const { tenant: Tenant, branch, table } = useParams();

    const group = localStorage.getItem("group");
    const [logo, setLogo] = useState(JSON.parse(group)?.logo || "");
    useEffect(() => {
        setLogo(JSON.parse(group)?.logo || "");
    }, [group]);

    const handleNavigation = async () => {
        const queryString = await getQueryParams();
        const basePath = table ? `/${Tenant}/${branch}/${table}/` : branch ? `/${Tenant}/${branch}/` : Tenant ? `/${Tenant}/` : "/";
        navigate(basePath + (queryString ? `?${queryString}` : ""));
    };

    return (
        <div className="col d-flex justify-content-center">
            <div className={cover ? "" : "logo-mid"} role="button" onClick={handleNavigation}>
                {<img className={AddOns?.["Online Ordering"] && style?.is_desktop_logo_centered && !cover ? "d-block d-lg-none" : cover ? "centered-logo" : ""} src={logo ? logo : Grip} alt="logo" height={70} />}
            </div>
        </div>
    );
}

export default Logo;
