import Main from "../components/Home";
import AdsModal from "../components/common/AdsModal";

function Home() {
    return (
        <>
            <Main />
            <AdsModal />
        </>
    );
}

export default Home;
