import React, { useEffect, useState } from "react";
import { useTheme, useLanguage, useCart } from "../../utils/Hooks";
import Logo from "./Logo";
import { useQueryClient } from "react-query";
import Text from "./Text";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

function HeaderMenu({ setOpenMenu, openMenu, headerRef, landing }) {
    const { table } = useParams();
    const { style, styleChange, AddOns } = useTheme();
    const [Direction, setDirection] = useState(style["direction-mode"]);
    const [Theme, setTheme] = useState(style["theme-mode"]);
    const { userLanguageChange, userLanguage } = useLanguage();
    const QueryClient = useQueryClient();

    useEffect(() => {
        setDirection(style["direction-mode"]);
        setTheme(style["theme-mode"]);
    }, [style]);

    const changeLanguage = () => {
        const direction = Direction === "ltr" ? "rtl" : "ltr";
        styleChange("direction-mode", direction);
        userLanguageChange(direction === "ltr" ? "en" : "ar");
        QueryClient.invalidateQueries();
    };

    const { Socket, Updates } = useCart();

    const callWaiter = () => {
        try {
            if (Socket?.OPEN && Socket?.readyState === WebSocket.OPEN) {
                Socket?.send(
                    JSON.stringify({
                        action: "call_waiter",
                        language: userLanguage,
                    })
                );
            }
        } catch (err) {
            console.error(err);
        }
    };

    const changeTheme = () => {
        const theme = Theme === "light-mode" ? "dark-mode" : "light-mode";
        styleChange("theme-mode", theme);
    };

    const handleCall = () => {
        if (style?.is_branch_closed) {
            toast.warn(<Text title={"BranchClosed"} />);
        } else {
            Updates?.is_calling || Updates?.is_checking_out ? null : callWaiter();
        }
    };

    return (
        <header className="header position-fixed bg-theme-round-opac text-white" style={{ ...(AddOns?.["Online Ordering"] ? {} : { width: "100%" }) }} ref={headerRef}>
            <div className="row">
                <div className="col-auto" style={{ width: "40%" }}>
                    {!landing && (
                        <button type="button" className="btn btn-default btn-44 btn-rounded menu-btn mx-2" onClick={() => setOpenMenu(!openMenu)}>
                            <i className="bi bi-list"></i>
                        </button>
                    )}
                    <div role={"button"} className="btn btn-default btn-44 btn-rounded mx-2" onClick={changeLanguage}>
                        {Direction === "ltr" ? "عربي" : "EN"}
                    </div>
                </div>
                <Logo />
                <div className="col-auto text-end" style={{ width: "40%" }}>
                    {table ? (
                        <div role={"button"} className="btn btn-default py-2 btn-rounded mx-2" style={{ filter: Updates?.is_calling || Updates?.is_checking_out ? "grayscale(70%)" : "", opacity: Updates?.is_calling || Updates?.is_checking_out ? "0.95" : "" }} onClick={handleCall}>
                            <i style={{ fontSize: 12 }} className="bi bi-telephone-fill mx-1"></i>
                            {Updates?.is_calling || Updates?.is_checking_out ? <Text title={"calling"} /> : <Text title={"callWaiter"} />}
                        </div>
                    ) : (
                        <div className="btn w-auto h-auto pt-1">
                            <span className="form-check form-switch position-relative">
                                <span className="bi bi-moon position-absolute" onClick={changeTheme} style={{ top: "8px", right: "5px" }}></span>
                                <input type="checkbox" className="form-check-input custom-checkbox" id="one" style={{ width: "50px", height: "25px" }} checked={Theme === "dark-mode"} onChange={changeTheme} />
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
}

export default HeaderMenu;
